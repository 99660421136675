import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [plans, setPlans] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const planCreateTemplate = {
        title: 'Plano',
        subtitle: 'Criar um novo plano',
        infos: [
            { key: 'name', type: "text", name: 'Nome', value: '', disabled: true, required: true },
            { key: 'description', type: "text", name: 'Descrição', value: '', disabled: true},
            { key: 'planItems', type: "text", name: 'Itens no plano (separados por ";")', value: '', disabled: true, required: true },
            { key: 'showOnWebsite', type: "checkbox", name: 'Mostrar plano', value: 0, disabled: true },
            { key: 'resources_nf', type: "number", name: 'Notas Fiscais', value: 0, disabled: true, required: true},
            { key: 'resources_monthlyMails', type: "number", name: 'Email diários', value: 0, disabled: true, required: true},
            { key: 'resources_activeForms', minValue: -1, type: "number", name: 'Formulários ativos', value: 0, disabled: true, required: true},
            { key: 'module_finances', type: "checkbox", name: 'Finanças', value: 0, disabled: true },
            { key: 'module_categories', type: "checkbox", name: 'Categorias', value: 0, disabled: true },
            { key: 'module_stock', type: "checkbox", name: 'Estoque', value: 0, disabled: true },
            { key: 'module_services', type: "checkbox", name: 'Serviços', value: 0, disabled: true },
            { key: 'module_human_resources', type: "checkbox", name: 'Recursos Humanos', value: 0, disabled: true },
            { key: 'module_clients', type: "checkbox", name: 'Clientes', value: 0, disabled: true },
            { key: 'module_suppliers', type: "checkbox", name: 'Fornecedores', value: 0, disabled: true },
            { key: 'module_schedule', type: "checkbox", name: 'Agenda', value: 0, disabled: true },
            { key: 'module_marketing', type: "checkbox", name: 'Marketing', value: 0, disabled: true },
            { key: 'module_forms', type: "checkbox", name: 'Formulários', value: 0, disabled: true },
            { key: 'module_stores', type: "checkbox", name: 'Lojas', value: 0, disabled: true },
            { key: 'module_automation', type: "checkbox", name: 'Automação', value: 0, disabled: true },
            { key: 'module_integrations', type: "checkbox", name: 'Integrações', value: 0, disabled: true },
            { key: 'module_fiscal', type: "checkbox", name: 'Notas fiscais', value: 0, disabled: true },
            { key: 'module_ai', type: "checkbox", name: 'Inteligência artificial', value: 0, disabled: true },
            { key: 'module_teams', type: "checkbox", name: 'Times', value: 0, disabled: true },
            { key: 'module_goals', type: "checkbox", name: 'Metas', value: 0, disabled: true },
            { key: 'module_vehicles', type: "checkbox", name: 'Veículos', value: 0, disabled: true },
            { key: 'module_patrimony', type: "checkbox", name: 'Patrimônios', value: 0, disabled: true },
            { key: 'module_leads', type: "checkbox", name: 'Leads', value: 0, disabled: true },
            { key: 'module_flows', type: "checkbox", name: 'Fluxos', value: 0, disabled: true },
        ]
    }

    const getPlans = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('plan/list', {
                page: page
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    general.notify('Você não tem permissão para acessar os planos', 'error');
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setPlans(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar planos', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getPlans(true);
    }, [page]);

    useEffect(() => {
        getPlans(false);
    }, [createModalActive, editModalActive]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        planCreateTemplate,
        currentItem,
        setCurrentItem,
        plans,
        page,
        setPage,
        totalPages,
        loading
    }
}

export default useController;
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const popInAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.95);
    }
    75% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
`;

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
    overflow: hidden;
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;

    @media (max-width: 1366px) {
        height: calc(100% - 50px);
    }

    @media (max-width: 500px) {
        height: 100%
    }
`;
export const operationsArea = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 500px) {
        width: calc(100% - 20px);
        padding: 10px;
        height: calc(100% - 210px);
    }
`;
export const calendarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 20px;
    border-radius: 10px;

    @media (max-width: 900px) {
        gap: 10px;
        flex-direction: column;
        overflow-y: hidden;
    }
`;
export const createTaskButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: 50px;
    align-self: center;
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 1.15em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
        box-shadow: 0 0 5px ${colors.black};
    }

    @media (max-width: 500px) {
        width: calc(100% - 20px);
        font-size: 1em;
    }
`;
/* show more events */
const showMoreEventsModalAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const showMoreEventsModal = styled.div<{ active: boolean }>`
    display: ${props => props.active ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
`;
export const showMoreEventsModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    background-color: ${colors.white};
    border-radius: 10px;
    padding: 20px;
    animation: ${showMoreEventsModalAnimation} 0.3s ease forwards;

    @media (max-width: 1200px) {
        width: 60%;
        height: 60%;
        margin-top: -100px;
    }

    @media (max-width: 900px) {
        width: 80%;
        height: 70%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }
`;
export const showMoreEventsModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
export const showMoreEventsModalTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
`;
export const showMoreEventsModalClose = styled(FontAwesomeIcon)`
    color: ${colors.grey};
    font-size: 1.5em;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
        color: ${colors.red};
    }
`;
export const showMoreEventsModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
`;
export const showMoreEvent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    background-color: ${colors.lightGrey};
    cursor: pointer;

    &:hover {
        background-color: ${colors.grey};
    }
`;
export const showMoreEventTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1em;
    font-weight: 600;
`;
export const showMoreEventDescription = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;
export const showMoreEventTime = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;
export const calendarList = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: 20%;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    padding: 10px;

    @media (max-width: 1366px) {
        width: 35%;
    }

    @media (max-width: 900px) {
        width: 100%;
        height: 50%;
        padding: 10px 0;
    }

    @media (max-width: 500px) {
        width: 100%;
        height: 43%;
        padding: 10px 0;
    }
`;
export const calendarListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    @media(max-width: 900px) {
        padding: 5px;
    }
`;
export const calendarListTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
    animation: ${popInAnimation} 0.3s ease forwards;

    @media(max-width: 900px) {
        font-size: 1.25em;
    }
`;
export const calendarListBody = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: calc(100% - 5px);
    height: 100%;
    overflow-y: auto;
`;
export const calendarListItem = styled.div<{index: number}>`
    display: flex;
    flex-direction: column;
    width: calc(90% - 35px);
    gap: 10px;
    padding: 15px;
    border-radius: 5px;
    background-color: ${colors.darkestGrey};
    cursor: pointer;
    transform: scale(0);
    animation: ${popInAnimation} 0.5s ${(props) => props.index * 0.1}s ease forwards;

    &:hover {
        background-color: ${colors.darkerGrey};
    }
`;
export const calendarListItemTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1em;
    font-weight: 600;
`;
export const calendarListItemTime = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;
export const calendarListItemDescription = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;
export const noEvents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 100%;
    height: 100%;
`;
export const noEventsIcon = styled(FontAwesomeIcon)`
    font-size: 5em;
    color: ${colors.grey};
    animation: ${popInAnimation} 0.5s ease forwards;

    @media(max-width: 900px) {
        font-size: 6em;
    }

    @media(max-width: 500px) {
        font-size: 4em;
    }
`;
export const noEventsText = styled.h1`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    color: ${colors.grey};
    animation: ${popInAnimation} 0.5s ease forwards;

    @media(max-width: 900px) {
        font-size: 1.75em;
    }

    @media(max-width: 500px) {
        font-size: 1.25em;
    }
`;
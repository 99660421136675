import React, { useState } from 'react';
import { useController } from './controller';
import { addCompanyModalProps } from '../../../utils/types';
import * as S from './styles';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'

import { plan } from './types';
import moment from 'moment';

const AddCompanyModal = (props: addCompanyModalProps) => {
    const controller = useController(props);
    

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onMouseDown={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onMouseDown={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Criar Empresa - {controller.stepName[controller.step].title}</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.uploadLogo>
                                    <div style={{ position: 'relative', display: 'flex' }}>
                                        <S.avatar logoImg={controller.currentLogo} maxInitials={2} textSizeRatio={5.25} style={{
                                            fontSize: '40px',
                                        }} size='150' name={controller.companyInfo.name}></S.avatar>
                                        <S.uploadTooltip logoImg={controller.currentLogo}>
                                            <S.uploadIcon icon={controller.general.icons.faUpload}></S.uploadIcon>
                                            <S.uploadText>Clique para adicionar uma logo</S.uploadText>
                                        </S.uploadTooltip>
                                        {/* title has a ' '[white space] so it doesnt show anything on hover */}
                                        <S.upload onInput={controller.handleInputFile} type='file' accept='image/*' title=' '></S.upload>
                                    </div>
                                </S.uploadLogo>
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome da empresa:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='name' value={controller.companyInfo.name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>CPF / CNPJ:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='document' value={controller.companyInfo.document}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Email:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='email' value={controller.companyInfo.email}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Telefone:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='phone' value={controller.companyInfo.phone}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                        {controller.step == 1 ?
                            <S.step active={controller.step == 1} mobileMod={true}>
                                <S.inputsArea occupyAll>
                                    <S.inputContainer>
                                        <S.inputLabel>CEP:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.zip_code' value={controller.companyInfo.address.zip_code}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Estado:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.state' value={controller.companyInfo.address.state}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Cidade:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.city' value={controller.companyInfo.address.city}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Bairro:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.neighborhood' value={controller.companyInfo.address.neighborhood}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Rua:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.street' value={controller.companyInfo.address.street}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Número:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.number' value={controller.companyInfo.address.number}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Complemento:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.complement' value={controller.companyInfo.address.complement}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}

                        {controller.step == 2 ?
                            <S.step active={controller.step == 2} mobileMod={true}>
                                <S.confirmDataArea>
                                    <S.confirmItem>
                                        <S.confirmLabel>Nome da empresa:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.name == '' ? 'Não informado' : controller.companyInfo.name}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>CPF / CNPJ:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.document == '' ? 'Não informado' : controller.companyInfo.document}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Email:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.email == '' ? 'Não informado' : ((controller.companyInfo.email.length >= 30) ? controller.companyInfo.email.slice(0, 28) + '...' : controller.companyInfo.email)}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Telefone:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.phone == '' ? 'Não informado' : controller.companyInfo.phone}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Endereço:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.address.zip_code == '' ? 'Não informado' : `${controller.companyInfo.address.street}, ${controller.companyInfo.address.number} - ${controller.companyInfo.address.neighborhood}, ${controller.companyInfo.address.city} - ${controller.companyInfo.address.state}`}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Período grátis:</S.confirmLabel>
                                        <S.confirmData>14 dias (até {moment().add(14, 'days').format('DD/MM/YYYY')})</S.confirmData>
                                    </S.confirmItem>
                                </S.confirmDataArea>
                            </S.step>
                            : null}

                        {controller.step == 3 ?
                            <S.step active={controller.step == 3} mobileMod={true}>
                                <S.congratulationsArea>
                                    <S.congratulationsIcon icon={controller.general.icons.faBuildingCircleCheck}></S.congratulationsIcon>
                                    <S.congratulationsTextArea>
                                        <S.congratulationsTitle>Parabéns! Sua empresa foi criada com sucesso!</S.congratulationsTitle>
                                        <S.congratulationsText>Você acaba de dar mais um passo em direção ao sucesso do seu negócio. Em instantes será enviado um email confirmando seu cadastro juntamente com a nota fiscal. Caso tenha qualquer dúvida contate-nos no suporte.</S.congratulationsText>
                                    </S.congratulationsTextArea>
                                </S.congratulationsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.progressBar>
                        <S.progress percentage={controller.calculateStepPercentage()}></S.progress>
                        <S.progressPercentage>
                            {controller.calculateStepPercentage().toFixed(0).toString() + '%'}
                        </S.progressPercentage>
                    </S.progressBar>
                    <S.nextBackBtns>
                        <S.nextBackBtn onClick={() => controller.changeStep('next')}>{controller.stepName[controller.step].nextBtn ? controller.stepName[controller.step].nextBtn : ''}</S.nextBackBtn>
                        {(controller.step == controller.stepName.length - 1 || controller.step == 0) ? null : <S.nextBackBtn onClick={() => controller.changeStep('back')}>{controller.stepName[controller.step].backBtn ? controller.stepName[controller.step].backBtn : ''}</S.nextBackBtn>}
                    </S.nextBackBtns>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default AddCompanyModal;
import styled, {keyframes} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';
import { useController } from './controller';

export const main = styled.div<{index: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 330px;
    height: 320px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 280px;
        padding: 0;
    }

    @media (max-width: 900px) {
        width: calc(100% - 20px);
        height: 280px;
        padding: 0;
        width: 100%;
    }

    @media (max-width: 500px) {
        max-height: 280px;
        height: auto;
    }
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (max-width: 500px) {
        height: auto;
    }
`;
export const cardTitles = styled.div`
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: ${fonts.main};
`;
export const cardTitle = styled.h3`
    font-size: 18px;
    font-weight: 800;
    padding: 0 5px 10px 5px;
    margin: 0 10px;
    border-bottom: 1px solid ${colors.grey};

    @media (max-width: 1366px) {
        font-size: 14px;
        padding: 10px 0;
    }
    @media (max-width: 700px) {
        font-size: 13px;
        text-align: center;
        padding: 15px 0;
    }
`;
export const cardTeam = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 500px) {
        max-height: 230px;
        height: auto;
    }
`;
export const member = styled.div<{index: number}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    border-bottom: 1px solid ${colors.grey};
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    :nth-child(even) {
        flex-direction: row-reverse;
    }

    :nth-child(odd) {
        text-align: right;
    }
        
    :last-child {
        border-bottom: none;
    }
`;
export const memberAvatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid ${colors.lightGrey};
`;
export const memberInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: ${fonts.main};
    font-size: 12px;
    font-weight: 600;
`;
export const memberName = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.darkeredGrey};
    font-family: ${fonts.main};

    @media (max-width: 1366px) {
        font-size: 12px;
    }

    @media (max-width: 700px) {
        font-size: 14px;
    }
`;
export const memberRole = styled.p`
    font-size: 12px;
    font-weight: 600;
    color: ${colors.darkGrey};
    font-family: ${fonts.main};

    @media (max-width: 1366px) {
        font-size: 10px;
    }

    @media (max-width: 700px) {
        font-size: 12px;
    }
`;
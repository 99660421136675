import styled, {keyframes} from 'styled-components';
import general from '../../../utils/general';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;

export const modalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999999;
`;
const modalAnimation = keyframes`
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;
export const modalMain = styled.form`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px;
    gap: 20px;
    overflow-y: auto;
    animation: ${modalAnimation} 0.5s;

    @media (max-width: 1200px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        width: 80%;
    }
`;
export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${colors.black};
`;
export const modalTitle = styled.div`
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
`;
export const modalClose = styled.div`
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;

    :hover {
        color: ${colors.red};
    }
`;
export const modalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px 20px 20px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

`;
export const modalInput = styled.input`
    width: calc(100% - 20px);
    padding: 10px;
    border: none;
    outiline: none;
    background-color: ${colors.backgroundWhite};
    border-bottom: 1px solid ${colors.black};
    font-family: ${fonts.main};
    font-size: 1rem;

    :focus {
        outline: none;
    }
`;
export const modalSelect = styled.select`
    width: 100%;
    padding: 10px;
    border: none;
    outiline: none;
    border-bottom: 1px solid ${colors.black};
    font-family: ${fonts.main};
    font-size: 1rem;
    background-color: ${colors.backgroundWhite};
    cursor: pointer;

    option {
        font-family: ${fonts.main};
        font-size: 1rem;
    }
    
    :focus {
        outline: none;
    }
`;
export const modalButton = styled.div`
    cursor: pointer;
    padding: 15px 10px;
    background-color: ${colors.backgroundWhite};
    color: ${colors.black};
    border-radius: 5px;
    font-family: ${fonts.main};
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 20px;
    border: 1px solid ${colors.black};
    transition-duration: 0.3s;
    text-transform: uppercase;

    :hover {
        box-shadow: 0 0 10px ${colors.black};
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;
export const result = styled.div`
    color: ${colors.black};
    font-size: 1.4rem;
    font-weight: 600;
    max-height: 400px;

    @media (max-width: 1200px) {
        font-size: 1.2rem;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`;
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toaster } from 'sonner';

const modalAnimation = keyframes`
    from {
        opacity: 0;
        trandform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;
export const supportModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999999;
`;
export const supportModalContent = styled.div`
    width: 400px;
    background-color: ${colors.black};
    border-radius: 10px;
    box-shadow: 0 0 10px ${colors.white};
    animation: ${modalAnimation} 0.5s;
    padding-bottom: 20px;

    @media (max-width: 500px) {
        width: 90%;
    }
`;
export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${colors.white};
`;
export const modalTitle = styled.div`
    color: ${colors.white};
    font-size: 1.5rem;
    font-weight: 600;
`;
export const modalClose = styled.div`
    cursor: pointer;
`;
export const closeIcon = styled(FontAwesomeIcon)`
    color: ${colors.white};
    font-size: 1.2rem;
`;
export const modalBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
`;
export const supportText = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
`;
export const supportIcon = styled(FontAwesomeIcon)<{size?: string}>`
    color: ${colors.white};
    font-size: ${props => props.size ? props.size : '1.2rem'};
    transition-duration: 0.1s;
    padding-top: 3px;
`;
export const supportContactText = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    text-align: center;
    cursor: pointer;
`;
export const supportContact = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition-duration: 0.3s;
    border: 1px solid ${colors.white};
    padding: 10px;
    width: 80%;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;

    &:hover {
        background-color: ${colors.white};
        color: ${colors.black};

        ${supportIcon} {
            color: ${colors.black};
        }

        ${supportContactText} {
            color: ${colors.black};
    }
`;
import React, { useRef } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';

const Checkbox = (props: any) => {
    const controller = useController(props);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <S.inputContainer key={Math.random()}>
            {/* props.field.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, '_') ?? props.randomName */}
            <S.input autoComplete='disable' placeholder='Digite um nome para o campo' id={`key_${props.field.key}`} width={(props.item?.infos?.filter((item: any) => item.key == props.field.key)[0].name.length ?? 0) + 2} onChange={(e) => controller.handleNameChange(e, props.randomName)} name={`key_${props.field.key}`} defaultValue={`${props.field.name ?? props.randomName}${props.field.required ? '*' : ''}`} disabled={props.field.disabled && props.field.disabled == true ? true : false} />
            <S.inputCheckbox type='checkbox' ref={inputRef} name={`value_${props.field.key}`} defaultChecked={props.field.value} id={`value_${props.field.key}`} />
            {!props.field.disabled ?
                <S.deleteIcon icon={general.icons.faTrash} onClick={() => {
                    controller.removeItem();
                }} />
                : null
            }
        </S.inputContainer>
    );
}

export default Checkbox;
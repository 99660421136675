import styled, { keyframes, css } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.backgroundWhite};

    @media(max-height: 800px){
        height: 85%;
    }

    @media(max-width: 500px){
        height: 96%;
    }
`;
export const planPeriodContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 1%;
    border-bottom: 1px solid ${colors.grey};
    gap: 50px;

    @media(max-width: 600px){
        gap: 5px;
    }
`;
export const planPeriod = styled.p<{ selected: boolean }>`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.selected ? colors.black : colors.darkGrey};
    margin: 10px 0;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.3s;
    flex: 1;

    :nth-child(2){
        padding: 0 25px;
        border-right: 1px solid ${colors.grey};
        border-left: 1px solid ${colors.grey};

        @media(max-width: 500px){
            padding: 0 15px;
        }
    }

    &:hover{
        color: ${colors.black};
    }

    @media(max-width: 600px){
        font-size: 13px;
    }
`;
export const plansArea = styled.div`
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    position: relative;

    @media(max-height: 800px){
        height: 60%;
    }

    @media(max-width: 900px){
        @media(min-width: 700px){
            flex-direction: column;
            height: 52vh;
        }
    }

    @media(max-width: 600px){
        overflow-x: hidden;
    }
`;
const planAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const plan = styled.div<{ planShowing?: number, selected: boolean }>`
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    height: 100%;
    z-index: 9999;

    &:hover{
        border: 1px solid ${colors.black};
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    }

    @media(max-width: 900px){
        @media(min-width: 700px){
            display: grid;
            grid-template: 
                "a a a a a a"
                "b b b c c c"
                "b b b c c c";
            width: 95%;
            height: 100%;
        }
    }

    @media(max-width: 600px){
        display: none;
        position: absolute;
        width: 74%;
        height: 92%;
        animation: ${planAnimation} .5s;

        ${props => props.planShowing && css`
            :nth-child(${props.planShowing}){
                display: flex;
            }
        `}
    }

    ${props => props.selected && css`
        border: 1px solid ${colors.black};
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

        ${planHireBtn}{
            background-color: ${colors.black};
            color: ${colors.backgroundWhite};
        }
    `}
`;
export const planTitle = styled.h3`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.black};
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
    grid-area: a;

    @media(max-width: 1500px){
        font-size: 15px;
    }
`;
const textAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const planItems = styled.div`
    height: 100%;
    overflow-y: auto;
    grid-area: b;
    opacity: 0;
    animation: ${textAnimation} 0.3s ease-in-out 0.05s forwards;

    @media(max-width: 900px){
        @media(min-width: 700px){
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 100%;
            width: 98%;
        }
    }
`;
export const planItem = styled.p`
    font-family: ${fonts.main};
    font-size: 12px;
    font-weight: 600;
    color: ${colors.black};
    margin: 7px 0;

    :before{
        content: '•';
        margin-right: 5px;
    }

    @media(max-width: 1500px){
        font-size: 11px;
        margin: 4px 0;

        @media(max-height: 700px){
            margin: 2px 0;
            font-size: 10px;
        }
    }

    @media(max-width: 900px){
        @media(min-width: 700px){
            width: 100%;
        }
    }

    @media(max-width: 600px){
        font-size: 11px;
        margin: 4px 0;
    }
`;
export const planPriceAndHire = styled.div`
    grid-area: c;
`;
export const planBackNextContainer = styled.div<{ toBeReversed: boolean }>`
    display: none;
    width: 100%;
    flex-direction: ${props => props.toBeReversed ? 'row-reverse' : 'row'};
    justify-content: space-between;

    @media(max-width: 600px){
        display: flex;
    }
`;
export const planBackNextMobile = styled(FontAwesomeIcon)`
    display: none;
    font-size: 30px;
    color: ${colors.black};
    cursor: pointer;
    transition-duration: 0.3s;

    @media(max-width: 600px){
        display: block;
    }
`;
export const planPrice = styled.p`
    font-family: ${fonts.main};
    width: 100%;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.darkGreen};
    margin: 10px 0 0 0;
`;
export const planMonthly = styled.p`
    font-family: ${fonts.main};
    font-size: 11px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: ${colors.darkerGrey};
    margin: 0 0 10px 0;
`;
export const planHireBtn = styled.p`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 800;
    padding: 10px;
    border: 1px solid ${colors.black};
    border-radius: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    color: ${colors.black};
    text-align: center;
    text-transform: uppercase;
`;
export const planConfirmBtn = styled.p`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 800;
    padding: 12px 10px;
    margin-top: 15px;
    border-radius: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    color: ${colors.black};
    background-color: trasnparent;
    border: 1px solid ${colors.black};
    text-align: center;
    text-transform: uppercase;
    
    &:hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }
`;
export const inputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 5px 0;
    position: relative;

    @media(max-width: 800px){
        width: 100%;
    }
`;
export const inputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${colors.black};
    text-decoration: capitalize;

    @media(max-width: 500px){
        font-size: 13px;
    }
`;
export const input = styled.input`
    font-family: ${fonts.main};
    wdith: 100%;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;
    -moz-appearance   : textfield;
    -webkit-appearance: none;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputSelect = styled.select`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    background-color: transparent;
    transition-duration: 0.3s;
    cursor: pointer;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputOption = styled.option`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
import general from '../../../../utils/general';
import { useEffect, useState } from 'react';

export const useController = (props: any) => {
    const [value, setValue] = useState(props.options[0].label ?? 'Nada informado');
    const [optionsShowing, setOptionsShowing] = useState(false);

    const handleSetValue = (value: string) => {
        setValue(value);
        setOptionsShowing(false);
    }

    return {
        general,
        value,
        handleSetValue,
        optionsShowing,
        setOptionsShowing
    };
}

export default useController;
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardProductService from '../../../components/app/Cards/CardProductService';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import Pagination from '../../../components/general/Pagination';

const Products = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Estoque' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`product/create`} canAddCustomFields={false} title='Produto' type={controller.general.enums.itemTypes.PRODUCT} item={controller.productCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal stockIncrement currentItem={controller.setCurrentItem} url={`product/update/${controller.currentItem._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.PRODUCT} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Estoque' />
                {controller.general.getCompany().status != 'TRIAL' && !controller.general.getCompany().plan.planModules.includes("module_stock") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(prev => !prev)}>
                                <NewItemComponent mainIcon='faBoxesStacked' pageItem='Produto' />
                            </div>
                            {(controller.products ?? []).map((item: any, index: number) => {
                                return (
                                    <div className='content-cards-wrapper' key={`product_${index}`} onClick={() => {
                                        const itemsFilled = [...controller.productCreateTemplate.infos]
                                        itemsFilled.forEach((itemFilled) => {
                                            itemFilled.value = item[itemFilled.key]
                                            if (itemFilled.type === 'money') itemFilled.value = controller.general.formatMoney((itemFilled.value ?? '').toString())
                                        })
                                        item.infos.map((info: any) => {
                                            if (itemsFilled.filter((itemFilled: any) => itemFilled.key == info.key).length == 0) {
                                                itemsFilled.push(info)
                                            }
                                        })
                                        controller.setCurrentItem({ ...item, infos: itemsFilled })
                                        controller.setEditModalActive(active => !active)
                                    }}>
                                        <CardProductService
                                            index={index + 1}
                                            id={item.id}
                                            key={index}
                                            title={item.name}
                                            image={(item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_product.jpg`}
                                            info={[
                                                {
                                                    icon: 'faBox',
                                                    text: item.stock ?? 0,
                                                    description: `Quantidade de itens no estoque`
                                                },
                                                {
                                                    icon: 'faDollarSign',
                                                    text: item.sells ?? 0,
                                                    description: `Quantidade de itens vendidos`
                                                }
                                            ]}
                                            itemsAmount={item.stock ?? 0}
                                            sellValueAmount={item.sellValueAmount ?? 0}
                                            unitPrice={item.price}
                                            sellsAmount={item.sellsValueAmount ?? 0}
                                        />
                                    </div>
                                );
                            })
                            }
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    )
}

export default Products;
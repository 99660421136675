import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'
import axios from 'axios';

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cupom, setCupom] = useState('');
    const [responseCupom, setResponseCupom] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(0); /* 0 - activating cupom ; 1 - cupom activated message */

    useEffect(() => {
        setStep(0);
        setCupom('');
    }, []);

    const tryCupom = async () => {
        if (cupom == "") {
            general.notify("É necessário inserir um cupom", "error");
            setErrorMessage("É necessário inserir um cupom");
            return;
        }
        else {
            setLoading(true);
            await general.api.post(`/promo-code/try`, {
                cupom: cupom
            }).then((response: any) => {
                if (response.data.status == 403 || response.data.status == 500 || response.data.status == 404) {
                    setLoading(false);
                    setErrorMessage(response.data.message);
                    return;
                };
                general.notify(response.data.message, 'success');
                setStep(1);
                setResponseCupom(response.data.data);
                setLoading(false);
            }).catch((err) => {
                general.notify(err.response.data.message, 'error');
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            document.getElementById('insertCupom')?.focus();
            setStep(0);
            setCupom('');
            setResponseCupom(null);
            setLoading(false);
            setErrorMessage('');
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    function keyDownHandler(e: any) {
        if (e.key === 'Escape') {
            props.setModalActive(false);
        }
        if (e.key === 'Enter') {
            if(step == 0){
                tryCupom();
            }
            else {
                setStep(0);
                setCupom('');
                setResponseCupom(null);
                props.setModalActive(false);
            }
        }
    }


    useEffect(() => {
        document.getElementById('retrieveCupomDiv')?.addEventListener('keydown', keyDownHandler);
        return () => {
            document.getElementById('retrieveCupomDiv')?.removeEventListener('keydown', keyDownHandler);
        }
    }, [cupom, step, responseCupom]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        });
        return () => {
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    props.setModalActive(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        setErrorMessage('');
    }, [cupom]);

    return {
        general,
        modalActive,
        setModalActive,
        cupom,
        setCupom,
        step,
        setStep,
        loading,
        setLoading,
        tryCupom,
        responseCupom,
        errorMessage
    };
}

export default useController;
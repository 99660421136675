import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';

const InputSelect = (props: any) => {
    const controller = useController(props);
    
    return (
        <S.inputContainer key={Math.random()}>
            <S.selectInput required={props?.field?.required ?? false} autoComplete='disable' id={`value_${props?.field?.key}`} onChange={(e) => controller.handleSetValue(e)} name={`${props?.field?.key}`} value={controller.value}>
                {(props?.field?.selectOptions ?? []).map((item: any) => (
                    <S.option key={item.value ?? item} value={item.value ?? item}>{item.name ?? item}</S.option>
                ))}
            </S.selectInput>
        </S.inputContainer>
    );
}

export default InputSelect;
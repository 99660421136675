import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemUser from '../../../components/app/Cards/ActionCard';
import Card from '../../../components/app/Cards/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Plans = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`promo-code/create/`} canAddCustomFields={false} title='Cupom' type={controller.general.enums.itemTypes.CUPOM} item={controller.promoCodeCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`promo-code/update/${controller.currentItem._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.CUPOM} item={{...controller.currentItem, can_be_deleted: false}} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} hasTimeline={true} /> : null}
            <MenuComponent menuPreset={2} />
            <S.generalArea>
                <HeaderBarComponent pageName='Códigos promocionais' />
                <S.operationsArea>
                    <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                        <NewItemUser mainIcon='faTicket' pageItem='Código'>
                            Adicionar <br /> código
                        </NewItemUser>
                    </div>
                    {(controller.codes ?? []).map((item: any, index) => {
                        return (
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.setCurrentItem({...item, infos: [
                                    { key: 'name', type: "text", name: 'Nome', value: item.name, disabled: true, required: true },
                                    { key: 'description', type: "text", name: 'Descrição', value: item.description, disabled: true, required: false},
                                    { key: 'cupom_type', type: "select", name: 'Tipo de cupom', value: item.cupom_type, disabled: true, selectOptions: [
                                        {
                                            name: 'Desconto em porcentagem',
                                            value: 'DISCOUNT_PERCENTAGE'
                                        },
                                        {
                                            name: 'Extensão de tempo',
                                            value: 'TIME_EXTENSION'
                                        }
                                    ], required: true},
                                    { key: 'discount_percentage', maxValue: 100, type: "percentage", name: 'Desconto %', value: item.discount_percentage, disabled: true, required: true},
                                    { key: 'system_time', type: "number", name: 'Tempo de uso (em dias)', value: item.system_time, disabled: true, required: true},
                                    { key: 'uses_left', type: "number", name: 'Usos restantes', value: item.uses_left ?? 0, disabled: true, required: true},
                                    { key: 'usesByCompany', type: "number", name: 'Usos por empresa', value: item.usesByCompany, disabled: true, required: true},
                                    { key: 'valid_until', type: "date", name: 'Válido até', value: item.valid_until?.split('T')[0], disabled: true, required: true},
                                ], timeline: item?.timeline?.length > 0 ? item.timeline : []})
                                controller.setEditModalActive(active => !active)
                            }} key={`accounts_${index}`}>
                                <Card title={controller.general.formatName(item.name) ?? 'Não informado'} subtitle={item.uses_left + ' usos restantes'} infos={[
                                    {name: 'Descrição', key: 'description', value: item.description ?? 'Não informado' },
                                    {name: 'Tipo de cupom', key: 'cupom_type', value: (item.cupom_type ? (item.cupom_type == "TIME_EXTENSION" ? 'Extensão de tempo' : 'Desconto em porcentagem') : 'Não informado') },
                                    {name: 'Desconto %', key: 'discount_percentage', value: item.discount_percentage ?? 'Não informado' },
                                    {name: 'Tempo de uso (em dias)', key: 'system_time', value: item.system_time ?? 'Não informado' },
                                    {name: 'Usos', key: 'uses', value: item.uses ?? '0' },
                                    {name: 'Usos restantes', key: 'uses_left', value: item.uses_left ?? '0' },
                                    {name: 'Usos por empresa', key: 'usesByCompany', value: item.usesByCompany ?? 'Não informado' },
                                    {name: 'Válido até', key: 'valid_until', value: (item.valid_until.toString()?.split('T')[0].split('-').reverse().join('/')) ?? 'Não informado' }
                                ]} />
                            </div>
                        )
                    })}
                </S.operationsArea>
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Plans;
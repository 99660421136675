import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '@utils/types';
import Particles from "@tsparticles/react";
import { Toaster } from 'sonner';

const SignUp = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            <Toaster richColors />
            <S.signupContainer onSubmit={e => controller.createAccount(e)}>
                <S.title>AREZDOT ERP</S.title>
                <S.inputDiv>
                    <S.inputTitle>Nome completo:</S.inputTitle>
                    <S.input type="text" name='name' autoComplete='disable'/>
                </S.inputDiv>
                <S.inputDiv>
                    <S.inputTitle>Email:</S.inputTitle>
                    <S.input type="email" name='email' autoComplete='disable'/>
                </S.inputDiv>
                <S.inputDiv>
                    <S.inputTitle>Senha:</S.inputTitle>
                    <S.input type="password" name='password'/>
                </S.inputDiv>
                <S.inputDiv>
                    <S.inputTitle>Confirmar senha:</S.inputTitle>
                    <S.input type="password" name='password_confirm'/>
                </S.inputDiv>
                <S.submit type='submit' className='signupBtn' value={'cadastrar'}/>
                <S.button onClick={() => controller.general.GoToRoute('login')} backgroundColor='transparent'>fazer login</S.button>
            </S.signupContainer>
            <S.outContainer>
                <S.mainText>{'arezdot erp, a ferramenta que vai impulsionar o crescimento da sua empresa'.toUpperCase()}</S.mainText>
            </S.outContainer>
            <Particles
                id="tsparticles"
                particlesLoaded={controller.particlesLoaded as any}
                options={controller.options}
            />
        </S.main>
    );
}

export default SignUp;
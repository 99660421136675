import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
    overflow: hidden;
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media(max-width: 1200px) {
        height: 90%;
    }

    @media(max-width: 900px) {
        height: 93%;
    }

    @media print {
        height: 0%;
    }
`;
export const financesArea = styled.div`
    width: calc(100% - 40px);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 5fr 1.1fr;
    gap: 30px;
    padding: 20px;

    @media(max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
    }
`;

export const operationsArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: ${colors.white};
    position: relative;
`;
export const operationsHeaderArea = styled.div`
    display: flex;
    flex-direction: column;
    height: 40px;
    padding: 10px;
    align-items: flex-end;
    justify-content: center;

    @media(max-width: 600px) {
        height: 30px;
        padding: 10px 10px 5px 10px;
    }
`;
export const operationsList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    gap: 20px;
`;
export const noOperations = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 25%;
    margin-top: calc((50%/2) - 100px);
    justify-self: center;
    align-self: center;
    padding: 20px;
    border-radius: 5px;
    background-color: ${colors.lightGrey};
    text-align: center;

    @media(max-width: 1366px) {
        width: 30%;
        margin-top: calc(40%/2);
    }

    @media(max-width: 1200px) {
        width: 50%;
        margin-top: calc((35%/2));
    }

    @media(max-width: 900px) {
        width: 80%;
        margin-top: calc((50%/2));
    }

    @media(max-width: 600px) {
        width: 75%;
        margin-top: calc((35%/2));
    }
`;
export const noOperationsIcon = styled(FontAwesomeIcon)`
    font-size: 2.5em;
    color: ${colors.darkerGrey};
`;
export const noOperationsText = styled.h3`
    font-family: ${fonts.main};
    font-size: 1.1em;
    color: ${colors.darkerGrey};
`;
export const operationsSelectLabel = styled.label`
    font-family: ${fonts.main};
    font-size: .85em;
    color: ${colors.darkerGrey};
    margin-right: 51px;
`;
export const operationSelect = styled.select`
    font-family: ${fonts.main};
    font-size: 1.1em;
    color: ${colors.darkerGrey};
    background-color: ${colors.white};
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
`;
export const operationSelectOption = styled.option`
    font-family: ${fonts.main};
    font-size: 1.1em;
    color: ${colors.darkerGrey};
    background-color: ${colors.white};
    cursor: pointer;
    outline: none;
    border: none;

    &:hover {
        border: 1px solid ${colors.darkerGrey};
    }
`;

export const operation = styled.div<{index:number, type: number}>`
    display: flex;
    flex-direction: row;
    width: calc(100% - 22px);
    height: auto;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    margin: 0 10px;
    padding: 15px 0;
    background: linear-gradient(325deg, rgba(169,169,169,1) 0%, rgba(249,249,249,1) 100%);
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.6);
    }

    @media(max-width: 900px) {
        height: auto ;
    }

    @media(max-width: 600px) {
        height: auto ;
        padding: 10px 0;
        ${props => props.type === 0 ? 'border-left: 5px solid ' + colors.red : 'border-left: 5px solid ' + colors.priceGreen};
    }
`;
export const operationAvatar = styled.img`
    height: 140px;
    width: 140px;
    object-fit: cover;
    margin: 0 20px 0 10px;
    border-radius: 5px;

    @media(max-width: 900px) {
        width: 100px;
        height: 100px;
    }
    
    @media(max-width: 600px) {
        display: none;
    }
`;
export const operationInfoBits = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px 0 10px;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 10px;

    @media(max-width: 900px) {
        padding: 0 10px;
    }
`;
export const operationInfoBit = styled.div`
    flex-grow: 1;
    height: auto;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);

    @media(max-width: 1366px) {
        min-width: 1px;
    }
`;
export const operationInfoBitTitle = styled.div`
    font-family: ${fonts.main};
    font-size: 0.75em;
    color: ${colors.darkerGrey};
    font-weight: bold;
    margin-bottom: 5px;

    @media(max-width: 1366px) {
        font-size: 0.7em;
    }

    @media(max-width: 900px) {
        font-size: 0.8em;
    }

    @media(max-width: 600px) {
        font-size: 0.75em;
    }
`;
export const operationInfoBitValue = styled.div`
    font-family: ${fonts.main};
    font-size: 0.9em;
    color: ${colors.darkeredGrey};
    font-weight: bold;

    @media(max-width: 1366px) {
        font-size: 0.75em;
    }
`;

export const optionsArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;

    @media(max-width: 900px) {
        display: grid;
        height: auto;
        grid-template-areas: 
        "cob cob cob cob cob cob cob" 
        "ba fa fa fa fa fa fa"
        "ba fa fa fa fa fa fa"
        "ba fa fa fa fa fa fa"
        "ba fa fa fa fa fa fa"
        "ba fa fa fa fa fa fa";
    }

    @media(max-width: 600px){
        display: grid;
        margin-top: -15px;
        gap: 10px;
        grid-template-areas:
        "cob cob cob cob cob cob cob"
        "ba ba ba ba ba ba ba"
        "fa fa fa fa fa fa fa";
    }
`;

export const operationButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: cob;
    width: 100%;

    @media(max-width: 900px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    @media(max-width: 600px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;
export const createOperationBtn = styled.h3`
    font-family: ${fonts.main};
    font-size: 1.1em;
    background-color: ${colors.priceGreen};
    color: ${colors.backgroundWhite};
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 12px 10px;
    text-align: center;
    transition: 0.2s;
    font-weight: 700;

    &:hover {
        background-color: ${colors.priceGreenHover};
    }

    @media(max-width: 1366px) {
        font-size: 0.8em;
    }

    @media(max-width: 900px) {
        font-size: 1.25em;
        align-self: center;
        padding: 25px 20px;
        width: 100%;
    }

    @media(max-width: 600px){
        font-size: 1em;
        align-self: center;
        padding: 12px 0px;
        width: 100%;
    }
`;

export const balanceArea = styled.div`
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-area: ba;
    background-color: ${colors.white};
    position: relative;

    @media(max-width: 600px) {
        padding: 10px;
    }
`;
export const balanceValue = styled.p<{ balanceType: number }>`
    font-family: ${fonts.main};
    width: 100%;
    font-size: 1.6em;
    color: ${props => props.balanceType === 1 ? colors.priceGreen : colors.red};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    font-weight: bold;

    @media(max-width: 1366px) {
        font-size: 1em;
    }

    @media(max-width: 900px) {
        font-size: 1.25em;
    }

    @media(max-width: 600px) {
        font-size: 0.9em;
    }
`;
export const balanceIcon = styled(FontAwesomeIcon)`
    font-size: 1.25em;

    @media(max-width: 600px) {
        font-size: 1em;
    }
`;

export const filterArea = styled.div`
    box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.2);
    border-radius: 10px;
    grid-area: fa;
    height: auto;
    background-color: ${colors.white};

    @media(max-width: 600px) {
        overflow-y: auto;
    }
`;
export const filterAreaHeader = styled.div<{filterActive: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    ${props => props.filterActive ? 'border-bottom: 1px solid ' + colors.grey : ''};
`;
export const filterAreaTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 1.1em;
    color: ${colors.darkeredGrey};
    font-weight: bold;
    text-align: left;
    grid-area: fat;

    @media(max-width: 600px) {
        font-size: 0.925em;
    }
`;
export const filterAreaIcon = styled(FontAwesomeIcon)<{filterActive: boolean}>`
    font-size: 1.1em;
    color: ${colors.darkeredGrey};
    cursor: pointer;
    transition: 0.2s;
    transform: ${props => props.filterActive ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
const filterAnimation = keyframes`
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
`;
export const filters = styled.div<{filterActive: boolean}>`
    display: ${props => props.filterActive ? 'flex' : 'none'};
    flex-direction: column;
    transition: 0.2s;
    transform-origin: top;
    animation: ${filterAnimation} 0.2s;
    padding-bottom: 10px;
`;
export const dateContainers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: dcs;
    
    @media(max-width: 600px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;
export const filterDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0 10px 20px;
    grid-area: fdc;

    @media(max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0px;
    }
`;
export const dateTitle = styled.label`
    font-family: ${fonts.main};
    font-size: 1em;
    color: ${colors.darkerGrey};
    font-weight: bold;

    @media(max-width: 600px) {
        font-size: 0.9em;
        margin-left: 5px;
    }
`;
export const dateInput = styled.input`
    font-family: ${fonts.main};
    font-size: 1em;
    color: ${colors.darkerGrey};
    font-weight: bold;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: ${colors.white};
    cursor: pointer;
    transition: 0.2s;
    margin-right: 10px;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        position: absolute;
        width: 100%;
        opacity: 0;
    }

    @media(max-width: 600px) {
        width: 100%;
        margin-right: 0;
        justify-self: center;
        align-self: center;
        padding: 0;
    }
`;

export const checkOptionsArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px;
    grid-area: coa;
    
    @media(max-width: 900px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
`;
export const checkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.2s;
`;
export const checkbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.darkeredGrey};
    border-radius: 5px;
    transition: 0.2s;
`;
export const checkboxIcon = styled(FontAwesomeIcon)`
    font-size: 0.75em;
    color: ${colors.darkeredGrey};
`;
export const checkTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 1em;
    color: ${colors.darkeredGrey};
    font-weight: bold;
`;
import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import general from '../../../utils/general';
import useController from './controller';

const popInAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.95);
    }
    75% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
`;

export const calendar = styled.div<{
    height?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    },
    width?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    },
    position?: string
}>`
    display: flex;
    flex-direction: row;
    position: ${props => props.position ? props.position : 'absolute'};
    top: 0;
    left: 0;
    width: ${props => props.width?.pc ? props.width?.pc : 'calc(100% - 2px)'};
    height: ${props => props.height?.pc ? props.height?.pc : 'auto'};
    border: 1px solid ${colors.darkeredGrey};
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    z-index: 99;

    @media(max-width: 900px){
        width: ${props => props.width?.tablet ? props.width?.tablet : 'calc(100% - 2px)'};
        height: ${props => props.height?.tablet ? props.height?.tablet : 'auto'};
    }

    @media(max-width: 500px){
        width: ${props => props.width?.mobile ? props.width?.mobile : 'calc(100% - 2px)'};
        height: ${props => props.height?.mobile ? props.height?.mobile : 'auto'};
    }
`;
export const date = styled.div<{calendarType: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0 20px 0;
    gap: 10px;

    ${props => props.calendarType != 'date' && css`
        border-right: 1px solid ${colors.darkeredGrey};    
    `}
`;
export const header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0 0 0;
    gap: 10px;

    @media(max-width: 500px){
        margin: 0;
    }
`;
export const month = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;

    @media(max-width: 500px){
        width: 85%;
    }
`;
export const prevMonth = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 1.5em;
`;
export const monthName = styled.div`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
    transform: scale(0);
    animation: ${popInAnimation} 0.3s ease forwards;
    cursor: pointer;
`;
export const nextMonth = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 1.5em;
`;
export const days = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 20px 0 10px 0;

    @media(max-width: 500px){
        margin: 10px 0 0 0;
    }
`;
export const day = styled.div<{index: number}>`
    font-family: ${fonts.main};
    font-size: 1em;
    font-weight: 600;
    transform: scale(0);
    animation: ${popInAnimation} 0.25s ${(props) => props.index * 0.03}s ease forwards;
`;
export const body = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex: 1;
`;

export const cell = styled.div<{ index: number, maxLength: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: 0.2s;
    transform: scale(0);
    animation: ${popInAnimation} 0.3s ${(props) => (props.index > props.maxLength  / 2 ? props.maxLength - props.index : props.index) * 0.03}s ease forwards;

    @media(max-width: 500px){
        height: 45px;
`;

export const cellContent = styled.div<{ hasDay: boolean, index: number, currentDate: boolean }>`
    font-family: ${fonts.main};
    font-size: 1em;
    width: 100%;
    height: 100%;
    align-content: center;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    ${props => props.currentDate && css`
        background-color: ${colors.orange};
    `}

    ${props => props.hasDay && css`
        :hover {
            background-color: ${props.currentDate ? colors.darkerOrange : colors.darkerGrey};
        }
    `}
`;
export const cellHasEvents = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    right: calc(50% - 15px);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${colors.orange};
`;
export const time = styled.div<{
    height?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    },
    width?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    }
    calendarType: string
}>`
    display: ${props => props.calendarType == 'date' ? 'none' : 'flex'};
    flex-direction: column;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 0 20px 0 20px;

    @media(max-width: 900px){
        flex-direction: column;
    }
`;
export const timeBlocks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @media(max-width: 900px){
        flex-direction: column;
        gap: 20px;
    }
`;
export const timeBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media(max-width: 900px){
        gap: 10px;
    }
`;
export const iconArrow = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 1.5em;
    padding: 5px 10px;
    background-color: ${colors.darkeredGrey};
    border-radius: 5px;
    transition: 0.2s;
    
    :hover{
        background-color: ${colors.darkerGrey};
    }

    @media(max-width: 900px){
        font-size: 1.25em;
        padding: 5px 15px;
    }
`;
export const timeText = styled.div`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
    transform: scale(0);
    animation: ${popInAnimation} 0.5s ease forwards;

    @media(max-width: 900px){
        font-size: 1.25em;
    }
`;
export const finishButton = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 1.5em;
    width: 100%;
    padding: 10px 0;
    background-color: ${colors.orange};
    border-radius: 5px;
    transition: 0.2s;

    :hover{
        background-color: ${colors.darkerOrange};	
    }

    @media(max-width: 900px){
        font-size: 1.25em;
        padding: 7px 15px;
        width: auto;
    }
`;
import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [codes, setCodes] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const promoCodeCreateTemplate = {
        title: 'Código promocional',
        subtitle: 'Criar um novo código',
        infos: [
            { key: 'name', allCaps: true, type: "text", name: 'Nome', value: '', disabled: true, required: true },
            { key: 'description', type: "text", name: 'Descrição', value: '', disabled: true, required: false },
            {
                key: 'cupom_type',
                name: 'Tipo de cupom',
                type: "select",
                value: '',
                required: true,
                disabled: true,
                selectOptions: [
                    {
                        name: 'Desconto em porcentagem',
                        value: 'DISCOUNT_PERCENTAGE'
                    },
                    {
                        name: 'Extensão de tempo',
                        value: 'TIME_EXTENSION'
                    }
                ]
            },
            { key: 'discount_percentage', type: "percentage", name: 'Desconto %', value: '0', disabled: true, required: true },
            { key: 'system_time', type: "number", name: 'Tempo de uso (em dias)', value: '0', disabled: true, required: true },
            { key: 'uses_left', type: "number", name: 'Usos restantes', value: '0', disabled: true, required: true },
            { key: 'usesByCompany', type: "number", name: 'Usos pela empresa', value: '1', disabled: true, required: true },
            { key: 'valid_until', type: "date", name: 'Válido até', value: '', disabled: true, required: true },
        ]
    }

    const getCodes = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('promo-code/list', {
                page: page
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    general.notify('Você não tem permissão para acessar os planos', 'error');
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setCodes(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar planos', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getCodes(true);
    }, [page]);

    useEffect(() => {
        getCodes(false);
    }, [createModalActive, editModalActive]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        promoCodeCreateTemplate,
        currentItem,
        setCurrentItem,
        codes,
        page,
        setPage,
        totalPages,
        loading
    }
}

export default useController;
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';

const InputText = (props: any) => {
    const controller = useController(props);

    return (
        <S.inputContainer key={Math.random()} hidden={props.hidden}>
            {/* props.field.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, '_') ?? props.randomName */}
            <S.input autoComplete='disable' placeholder='Digite um nome para o campo' id={`key_${props.field.key}`} width={(props.item?.infos?.filter((item: any) => item.key == props.field.key)[0]?.name?.length ?? props?.field?.name?.length ?? 0) + 2} onChange={(e) => controller.handleNameChange(e, props.randomName)} name={`key_${props.field.key}`} defaultValue={`${props.field.name?.replace(/\*/g, '') ?? props.randomName}${props.field.required ? '*' : ''}`} disabled={props.field.disabled && props.field.disabled == true ? true : false} />
            {props.type == 'percentage' ?
                <S.input autoComplete='disable' type={'number'} onChange={(e) => {
                    if(parseFloat(e.target.value) > (props.field.maxValue ?? 100)) e.target.value = (props.field.maxValue ?? '100');
                    if(parseFloat(e.target.value) < (props.field.minValue ?? 0)) e.target.value = (props.field.minValue ?? '0');
                    controller.handleChange(e);
                }} required={props.field.required ?? false} name={`value_${props.field.key}`} defaultValue={props.field.value} placeholder={props.field.placeholder ?? 'Digite aqui'} id={`value_${props.field.key}`} />
                : <S.input autoComplete='disable' type={(props.type == 'phone' ? 'text' : props.type) ?? 'text'} onChange={(e) => {
                    if(props.field.mask) e.target.value = general.customMask(e.target.value, props.field.mask);
                    if(props.field.allCaps) e.target.value = e.target.value.toUpperCase();
                    if(props.field.maxValue) if(parseFloat(e.target.value) > props.field.maxValue) e.target.value = props.field.maxValue;
                    if(props.field.minValue) if(parseFloat(e.target.value) < props.field.minValue) e.target.value = props.field.minValue;
                    controller.handleChange(e)
                }} required={props.field.required ?? false} name={`value_${props.field.key}`} defaultValue={props.field.value} placeholder={props.field.placeholder ?? 'Digite aqui'} id={`value_${props.field.key}`} />
            }

            {!props.field.disabled ?
                <S.deleteIcon icon={general.icons.faTrash} onClick={() => {
                    controller.removeItem();
                }} />
                : null
            }
        </S.inputContainer>
    );
}

export default InputText;
import general from '../../../utils/general';
import { useState, useRef, useEffect } from 'react';

export const useController = () => {
    const [companyStatus, setCompanyStatus] = useState<string>(general.getCompany().status);
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>('');

    useEffect(() => {
        setStatusMessage(() => {
            switch (companyStatus) {
                case 'TRIAL':
                    return "Não há um plano ativo, por favor escolha um:";
                case 'NO_PLAN':
                    return "Não há um plano ativo, por favor escolha um:";
                case 'NO_CREDIT_CARD':
                    return "Não há um cartão cadastrado, por favor cadastre um:";
                case 'ACTIVE':
                    return "Estamos processando seu pagamento, por favor aguarde alguns minutos";
                case 'PAYMENT_PENDING':
                    return "Estamos processando seu pagamento, por favor aguarde alguns minutos";
                case 'CARD_REFUSED':
                    return "Falha no pagamento, por favor insira um novo cartão, ou contate o suporte:";
                default:
                    return "Ocorreu um erro ao processar seu pagamento, por favor contate o suporte:";
            }
        });
    }, [companyStatus]);

    const alterCompanyStatus = (status: string) => {
        setCompanyStatus(() => status);
    }

    return {
        general,
        companyStatus,
        supportModalOpen,
        statusMessage,
        setSupportModalOpen,
        setCompanyStatus,
        alterCompanyStatus
    };
}

export default useController;
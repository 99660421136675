import general from '../../../utils/general';
import { useState, useRef, useEffect } from 'react';
import { plan } from './types';
import axios from 'axios';

export const useController = (props: any) => {
    const [plans, setPlans] = useState<plan[]>([]);
    const [planShowing, setPlanShowing] = useState(2);
    const [cupomDiscount, setCupomDiscount] = useState<number>(0);
    const [planSelected, setPlanSelected] = useState<string>('');
    const [companyInfo, setCompanyInfo] = useState<any>({
        logo: { name: "", type: "", size: "", result: "" },
        name: "",
        document: "",
        email: "",
        phone: "",
        planPeriod: 12,
        planSelected: "",
        cupom: "",
        address: {
            zip_code: "",
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
            country: ""
        },
        cc: {
            number: "",
            name: "",
            expiration: "",
            cvc: "",
            installments: 1,
            focus: ""
        }
    });
    const [discounts, setDiscounts] = useState({
        monthly: 1,
        semestral: 0.95,
        anual: 0.9
    });

    const getPlans = () => {
        general.api.post('/plan/list', {
            page: 0,
            limit: 9999999999999
        }).then((response) => {
            if (response.status === 200) {
                setPlans(response.data.data.data.filter((plan: any) => plan.showOnWebsite === true).sort((a: any, b: any) => a.value - b.value));
                setCompanyInfo((prev: any) => ({ ...prev, planSelected: response.data.data.data[1]._id }));
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getPlans();
    }, []);

    const changeShowingPlan = (direction: 'next' | 'back') => {
        if (direction === 'next') {
            if (planShowing == 4) return;
            setPlanShowing(planShowing + 1);
        }
        if (direction === 'back') {
            if (planShowing == 2) return;
            setPlanShowing(planShowing - 1);
        }
    }

    const handleInputFocus = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setCompanyInfo((prev: any) => ({ ...prev, cc: { ...prev.cc, focus: e.target.name } }));
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'document':
                return general.customMask(value, (value.replace(/[^0-9]/g, '').length >= 12 ? '##.###.###/####-##' : '###.###.###-##'), "#", true);
            case 'phone':
                return general.phoneMask.customPhoneMask(value, '55');
            case 'cc.number':
                return general.customMask(value, '#### #### #### ####', "#", true);
            case 'cc.expiration':
                return general.customMask(value, '##/##', "#", true);
            case 'cc.cvc':
                return general.customMask(value, '###', "#", true);
            case 'cc.installments':
                return parseInt(value);
            case 'address.zip_code': {
                if (value.length >= 8) {
                    axios.get(`https://brasilapi.com.br/api/cep/v1/${value.replace('-', '').replace('.', '')}`).then((response) => {
                        if (response.status === 200) {
                            setCompanyInfo((prev: any) => ({ ...prev, address: { ...prev.address, street: response?.data?.street ?? '', neighborhood: response?.data?.neighborhood ?? '', city: response?.data?.city, state: response?.data?.state ?? '' } }));
                        }
                    }).catch((err) => { });
                }
                return general.customMask(value, '##.###-###', "#", true);
            }
            default:
                return value;
        }
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev: any) => ({ ...prev, [name]: { ...prev.cc, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const hirePlan = () => {
        general.api.post('/company/hireplan/' + general.getCompany()._id, {
            plan: companyInfo.planSelected,
            plan_period: companyInfo.planPeriod,
            planValue: plans.find((plan: any) => plan._id === companyInfo.planSelected)?.value,
            installments: companyInfo.cc.installments,
        }).then((response) => {
            if (response.status === 200) {
                general.notify('Plano contratado com sucesso', 'success');
                setCompanyInfo((prev: any) => ({ ...prev, planSelected: '', planPeriod: 12 }));
                getPlans();
                general.api.get('/account-company/get/' + general.getCompany()._id).then((response) => {
                    if (response.status === 200) {
                        let dataObject = response.data.data[0]
                        localStorage.setItem('company', dataObject.company._id);
                        localStorage.setItem('companyObject', JSON.stringify(dataObject.company));
                        props.setCompanyStatus && props.setCompanyStatus(dataObject.company.status);
                    }
                }).catch((err) => {
                    console.log(err);
                    general.notify('Erro ao buscar empresa', 'error');
                });
            }
        }).catch((err) => {
            general.notify('Erro ao contratar plano', 'error');
        });
    }

    return {
        general,
        plans,
        setPlans,
        planShowing,
        setPlanShowing,
        changeShowingPlan,
        companyInfo,
        handleInputFocus,
        setCompanyInfo,
        discounts,
        cupomDiscount,
        setCupomDiscount,
        handleSelectChange,
        hirePlan,
        planSelected,
        setPlanSelected
    };
}

export default useController;
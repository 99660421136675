import general from '../../../../utils/general';
import { FormType } from '../FormModal/enum'
import { useState, useEffect } from 'react';
import moment from 'moment';

export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [codeModalActive, setCodeModalActive] = useState(false);
    const [formDeleted, setFormDeleted] = useState(false);
    const [codeForIntegration, setCodeForIntegration] = useState('');
    const [form, setForm] = useState<any>({
        title: '',
        description: '',
        thanks_active: false,
        form_type: FormType.SURVEY,
        thanks_message: '',
        thanks_btn_title: '',
        thanks_btn_link: '',
        fields_array: [],
        schedule: {
            allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
            time_in_minutes: 15,
            time_day_start: '07:00',
            time_day_end: '18:00',
        },
    });

    const getFormFromSlug = async (showLoader?: boolean) => {
        try {
            const slug = window.location.pathname.split('/').pop();
            showLoader == true && setLoading(true);
            const response = await general.api.get('form/slug/' + slug);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setForm(response.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar formulário', 'error');
            setLoading(false);
        }
    }

    const deleteForm = async () => {
        try {
            setLoading(true);
            const response = await general.api.delete('form/delete/' + form._id);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setLoading(false);
                    return;
                }
                setFormDeleted(true);
                general.notify('Formulário excluído com sucesso, redirecionando...', 'success');
                setTimeout(() => {
                    window.location.href = '/app/forms';
                }, 2000);
                setEditModalActive(false);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao deletar formulário', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getFormFromSlug(true);
    }, []);

    useEffect(() => {
        getFormFromSlug(false);
    }, [editModalActive]);

    useEffect(() => {
        if (form) {
            let code = '<html lang="pt-br">\n';
            code += `
                <style>
                    * > * {
                        transition-duration: .3s;
                        margin: 0;
                        padding: 0;
                    }
                    
                    form {
                        display: flex;
                        flex-direction: column;
                        background-color: black;
                        padding: 20px;
                        border-radius: 20px;
                        width: 100%;
                    }

                    .labels {
                        font-family: Arial, sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 5px;
                        color: white;
                    }

                    .inputs {
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        resize: none;
                        outline: none;
                    }

                    .textAreas {
                        height: 150px;
                    }

                    .selectOptions {
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .checkboxWrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        padding: 10px 0;
                        gap: 10px;
                    }

                    .radios,
                    .checkboxes {
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        margin-right: 5px;
                        width: 25px;
                        height: 25px;
                    }

                    button {
                        font-family: Arial, sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        padding: 10px 20px;
                        border-radius: 5px;
                        background-color: #000;
                        color: #fff;
                        border: 1px solid white;
                        cursor: pointer;
                        margin-top: 10px;
                        margin-top: 20px;
                    }

                    button:hover {
                        background-color: white;
                        color: black;
                    }

                    #poweredBy {
                        font-family: Arial, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        color: white;
                        margin-top: 20px;
                        text-align: center;
                    }

                    #poweredBy a {
                        color: white;
                        text-decoration: underline;
                    }
                </style>
            `;
            code += '<form id="submitFormArezdot" onsubmit="formSubmit()">\n';
            code += '<input type="hidden" name="answer_start" value="">';
            if (form.form_type != FormType.SURVEY && form.form_type != FormType.SCHEDULE && form.form_type != FormType.LEAD) {
                code += '<label class="labels">Nome</label>\n';
                code += '<input autoComplete="off" class="inputs texts" type="text" name="person_name" required>';
                code += '<label class="labels">Email</label>\n';
                code += '<input autoComplete="off" class="inputs texts" type="text" name="person_email" required>';
            }
            if (form.form_type == FormType.EMPLOYEE) {
                code += '<label class="labels">Função na empresa (Cargo)</label>\n';
                code += '<input autoComplete="off" class="inputs texts" type="text" name="job_title" required>';
            }
            if (form.form_type == FormType.LEAD) {
                code += '<label class="labels">Nome</label>\n';
                code += '<input autoComplete="off" class="inputs texts" type="text" name="name" required>';
            }
            if (form.form_type == FormType.SCHEDULE) {
                let day_start_in_minutes = parseInt(form.schedule.time_day_start);
                let day_end_in_minutes = parseInt(form.schedule.time_day_end);
                let time_in_minutes_for_booking = parseInt(form.schedule.time_in_minutes);
                code += '<label class="labels">Nome</label>\n';
                code += '<input autoComplete="off" class="inputs texts" type="text" name="person_name" required>';
                code += '<label class="labels">Data de agendamento</label>\n';
                code += '<input autoComplete="off" class="inputs dates" type="date" name="chosen_date" required>';
                code += '<label class="labels">Hora de agendamento (15 minutos)</label>\n';
                code += '<select autoComplete="off" class="inputs selects" name="chosen_time" required>';
                while ((day_start_in_minutes + time_in_minutes_for_booking) <= day_end_in_minutes) {
                    let time = moment().startOf('day').add(day_start_in_minutes, 'minutes').format('HH:mm');
                    code += `<option class="selectOptions" value="De ${time} até ${moment().startOf('day').add(day_start_in_minutes + time_in_minutes_for_booking, 'minutes').format('HH:mm')}">De ${time} até ${moment().startOf('day').add(day_start_in_minutes + time_in_minutes_for_booking, 'minutes').format('HH:mm')}</option>`;
                    day_start_in_minutes += time_in_minutes_for_booking;
                }
                code += '</select>';
            }
            form.fields_array.map((field: any) => {
                if (field.type != 'checkbox') {
                    code += '<label class="labels">' + field.title + '</label>\n';
                }
                if (field.type == 'text' || field.type == 'date') {
                    code += '<input autoComplete="off" class="inputs ' + field.type + 's" type="' + field.type + '" name="' + field.id + '" ' + (field.required ? 'required' : '') + '>';
                }
                else if (field.type == 'numeric' || field.type == 'money' || field.type == 'phone') {
                    code += '<input ' + (field.type == 'money' ? (`onkeyup="formatMoney('${field.id}')"`) : (field.type == 'phone' ? `onkeyup="phoneMask('${field.id}')"` : `onkeyup="formatNumber('${field.id}')")`)) + ' autoComplete="off" class="inputs ' + field.type + 's" type="text" name="' + field.id + '" ' + (field.required ? 'required' : '') + '>';
                }
                else if (field.type == 'textarea') {
                    code += '<textarea  autoComplete="off" class="inputs textAreas" name="' + field.id + '" ' + (field.required ? 'required' : '') + '></textarea>';
                }
                else if (field.type == 'select') {
                    code += '<select autoComplete="off" class="inputs selects" name="' + field.id + '" ' + (field.required ? 'required' : '') + '>';
                    field.options.map((option: any, index: number) => {
                        code += '<option class="selectOptions" value="' + option + '">' + option + '</option>';
                    });
                    code += '</select>';
                }
                else if (field.type == 'radio') {
                    field.options.map((option: any) => {
                        code += '<input autoComplete="off" class="inputs radios" type="radio" name="' + field.id + '" value="' + option.value + '" ' + (field.required ? 'required' : '') + '>' + option.label;
                    });
                }
                else if (field.type == 'checkbox') {
                    code += '<div class="checkboxWrapper">'
                    code += '<label class="labels">' + field.title + '</label>\n';
                    code += '<input autoComplete="off" class="inputs checkboxes" type="checkbox" name="' + field.id + '" ' + (field.required ? 'required' : '') + '>';
                    code += '</div>'
                }
            });
            code += '<button type="submit">Enviar</button>';
            code += '<span id="poweredBy">Powered by <a href="https://arezdot.com" target="_blank">Arezdot</a></span>';
            code += `
                <script>
                    document.addEventListener('DOMContentLoaded', () => {
                        document.querySelector(\`input[name="answer_start"]\`).value = new Date().toISOString()
                    })
                    function formSubmit() {
                        const form = document.querySelector('#submitFormArezdot')
                        var url = "${process.env.REACT_APP_API_URL}public/form/integration/answer/${form._id}";
                        var xhr = new XMLHttpRequest();
                        xhr.open("POST", url, true);
                        xhr.setRequestHeader("Content-Type", "application/json");
                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === 4 && xhr.status === 200) {
                                console.log(xhr.responseText);
                            }
                        };
                        var data = {};
                        for (const input of form) {
                            if (input.name) {
                                data[input.name] = input.value
                            }
                        }
                        xhr.send(JSON.stringify(data));
                    }
                        
                    const formatNumber = (name, defaultNumber = 0, allowZero = false, allowNegatives = false) => {
                        let element = document.querySelector(\`input[name="\${name}"]\`)
                        let value = element.value
                        let formattedValue = value.toString().replace(/\\D/g, '')
                        if (formattedValue.length > 6) {
                            formattedValue = formattedValue.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, '$1.')
                        }
                        formattedValue = isNaN(parseFloat(formattedValue)) ? defaultNumber.toString() : formattedValue
                        if (!allowZero && parseFloat(formattedValue) == 0) {
                            formattedValue = defaultNumber.toString()
                        }
                        if (!allowNegatives && parseFloat(formattedValue) < 0) {
                            formattedValue = defaultNumber.toString()
                        }
                        element.value = formattedValue
                    }

                    const formatMoney = (name) => {
                        let element = document.querySelector(\`input[name="\${name}"]\`)
                        let value = element.value
                        let formattedValue = value.replace(/\\D/g, '')
                        formattedValue = (parseInt(formattedValue) / 100).toFixed(2).replace('.', ',')
                        if (formattedValue.length > 6) {
                            formattedValue = formattedValue.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, '$1.')
                        }
                        formattedValue = isNaN(parseFloat(formattedValue)) ? '0,00' : formattedValue
                        element.value = formattedValue
                    }
                    const phoneMask = (name) => {
                        let element = document.querySelector(\`input[name="\${name}"]\`)
                        let value = element.value
                        const onlyNumbers = value?.replace(/[^\\d]/g, '') ?? ''
                        let formattedValue = \`\`
                        if (onlyNumbers?.length <= 2) {
                            return element.value = onlyNumbers
                        }
                        if (onlyNumbers?.length <= 7) {
                            return element.value = \`(\${onlyNumbers?.slice(0, 2)}) \${onlyNumbers?.slice(2)}\`
                        }
                        if(onlyNumbers?.length <= 10) {
                            formattedValue = \`(\${onlyNumbers?.slice(0, 2)}) \${onlyNumbers?.slice(2, 6)}-\${onlyNumbers?.slice(6)}\`
                        }
                        else {
                            formattedValue = \`(\${onlyNumbers?.slice(0, 2)}) \${onlyNumbers?.slice(2, 7)}-\${onlyNumbers?.slice(7, 11)}\`
                        }
                        element.value = formattedValue
                    }
                </script>
            `;
            code += '</form>';
            code += '</html>';
            setCodeForIntegration(code);
        }
    }, [form]);

    return {
        general,
        form,
        loading,
        permissionBlock,
        editModalActive,
        FormType,
        deleteModalActive,
        formDeleted,
        codeModalActive,
        codeForIntegration,
        setFormDeleted,
        getFormFromSlug,
        setDeleteModalActive,
        deleteForm,
        setEditModalActive,
        setCodeModalActive,
        setCodeForIntegration,
    }
}

export default useController;
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemProps } from '../../../../utils/types';
import moment from 'moment-timezone';

const AutomationCard = (props: any) => {
    const controller = useController(props);

    return (
        <S.main index={props.index ?? 0}>
            {controller.deleteConfirmation ? (
                <>
                    <S.deleteCard>
                        <S.cardData>
                            <S.deleteTitle>Tem certeza que deseja excluir essa automação?</S.deleteTitle>
                        </S.cardData>
                        <S.cardActions>
                            <S.actionDeleteIcon onClick={() => controller.setDeleteConfirmation(false)} icon={controller.general.icons.faTimes} />
                            <S.actionDeleteIcon onClick={() => {
                                props.deleteAutomation(props.automation?._id)
                                controller.setDeleteConfirmation(false)
                            }} icon={controller.general.icons.faCheck} />
                        </S.cardActions>
                    </S.deleteCard>
                </>
            ) : (
                <>
                    {!controller.isFlipped ? (
                        <S.card onClick={() => controller.setIsFlipped(true)}>
                            {/*  @ts-expect-error */}
                            <S.icon playAnimation={props.playAnimation ?? true} icon={controller.general.icons[props.mainIcon ?? 'faAdd']} />
                            <S.cardData>
                                <S.title>{props.children}</S.title>
                            </S.cardData>
                        </S.card>
                    ) : (
                        <S.cardBack onClick={() => controller.setIsFlipped(false)}>
                            <S.cardDataBack>
                                <S.dataBit>
                                    <S.infoTitle>Nome:</S.infoTitle>
                                    <S.infoData>{props.automation?.title ?? 'Não informado'}</S.infoData>
                                </S.dataBit>
                                <S.dataBit>
                                    <S.infoTitle>Status:</S.infoTitle>
                                    <S.infoData>{props.automation?.active ? 'Ativada' : 'Desativada'}</S.infoData>
                                </S.dataBit>
                                <S.dataBit>
                                    <S.infoTitle>Criação:</S.infoTitle>
                                    <S.infoData>{moment(props.automation?.createdAt).format('DD/MM/YYYY') ?? 'Não informado'}</S.infoData>
                                </S.dataBit>
                                <S.dataBit>
                                    <S.infoTitle>Usos:</S.infoTitle>
                                    <S.infoData>{props.automation?.uses ?? 0}</S.infoData>
                                </S.dataBit>
                            </S.cardDataBack>
                            <S.cardActions>
                                <S.actionIcon title={'Voltar'} onClick={(e: any) => {
                                    e.stopPropagation()
                                    controller.setIsFlipped(false)
                                }} icon={controller.general.icons.faArrowLeft} />
                                <S.actionIcon title={'Excluir'} onClick={(e: any) => {
                                    e.stopPropagation()
                                    controller.setDeleteConfirmation(true)
                                    controller.setIsFlipped(false)
                                }} icon={controller.general.icons.faTrash} />
                                <S.actionIcon title={props.automation?.active ? 'Desativar' : 'Ativar'} onClick={(e: any) => {
                                    e.stopPropagation()
                                    props.onOffAutomation(props.automation?._id, !props.automation?.active)
                                }} icon={props.automation?.active ? controller.general.icons.faCirclePause : controller.general.icons.faCirclePlay} />
                            </S.cardActions>
                        </S.cardBack>
                    )}
                </>
            )}
        </S.main>
    );
}

export default AutomationCard;
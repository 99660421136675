import styled from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;

    &::after {
        content: '';
        flex: auto;
    }
`;

/* Create modal */
export const modal = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999;
`;
export const modalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    padding: 20px;
    gap: 20px;
    animation: fadeIn 0.5s forwards;

    @media (max-width: 900px) {
        width: 80%;
    }
`;
export const modalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;
export const modalTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 24px;
`;
export const closeModal = styled.button`
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
`;
export const modalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding: 20px;
`;
export const inputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    animation: fadeIn 0.5s forwards;
`;
export const inputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
`;
export const input = styled.input`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 15px 10px;
    border: none;
    outline: none;
    background: ${colors.black};
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
`;
export const modalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;
export const automationButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
    border: 1px solid ${colors.white};
    cursor: pointer;
    width: 100%;
    height: 50px;
`;
export const automationBtnIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;
export const automationBtnText = styled.p`
    color: ${colors.white};
    border: none;
    border-radius: 5px;
    border: 1px solid ${colors.white};
    cursor: pointer;
    width: 100%;
    align-content: center;
    height: 50px;
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    :hover {
        background-color: ${colors.white};
        color: ${colors.black};
    }
`;
import styled, {keyframes} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';
import { useController } from './controller';

export const main = styled.div<{index: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 330px;
    height: 320px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 240px;
    }

    @media (max-width: 900px) {
        width: 190px;
    }

    @media (max-width: 700px) {
        width: calc(100% - 20px);
        padding: 20px 10px;
        min-height: 180px;
        align-items: center;
    }
`;

export const title = styled.p`
    font-family: ${fonts.main};
    font-size: 22px;
    font-weight: 700;
    color: ${colors.black};
    margin-bottom: 10px;
    padding: 0;
    text-align: center;

    @media (max-width: 1366px) {
        font-size: 18px;
    }

    @media (max-width: 900px) {
        font-size: 16px;
    }

    @media (max-width: 700px) {
        font-size: 18px;
    }
`
export const description = styled.p`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};
    margin: 0;
    padding: 0 20px;
    text-align: center;
    display: flex;
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;

    @media (max-width: 1366px) {
        font-size: 14px;
    }

    @media (max-width: 900px) {
        font-size: 12px;
    }
`
export const infoArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    padding: 0 20px;
    margin-top: 10px;
    gap: 25%;
`
export const infoBit = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
        margin-top: 15px;
    }
`
export const infoBitTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 700;
    color: ${colors.darkerGrey};

    @media (max-width: 1366px) {
        font-size: 14px;
    }

    @media (max-width: 900px) {
        font-size: 14px;
    }

    @media (max-width: 700px) {
        font-size: 16px;
    }
`
export const infoBitValue = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 700;
    color: ${colors.darkerGrey};

    @media (max-width: 1366px) {
        font-size: 14px;
    }

    @media (max-width: 900px) {
        font-size: 14px;
    }

    @media (max-width: 700px) {
        font-size: 16px;
    }
`
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import PlanModal from '../PlanModal';
import EditCompanyPayment from '../EditCompanyPayment';
import SupportModal from '../SupportModal';

const PaymentMissing = (props: any) => {
    const controller = useController();
    return (
        <S.main>
            {controller.supportModalOpen && <SupportModal setModalOpen={controller.setSupportModalOpen} />}
            <S.errorInfoWindow>
                <S.errorHeader>
                    <S.errorTitle
                        warning={(controller.companyStatus == 'ACTIVE' || controller.companyStatus == 'PAYMENT_PENDING') ? false : true}
                    >{controller.statusMessage}</S.errorTitle>
                    <S.supportBtn onClick={() => controller.setSupportModalOpen(true)} icon={controller.general.icons.faHeadset} />
                </S.errorHeader>
                {(controller.companyStatus == 'NO_PLAN' || controller.companyStatus == 'TRIAL') ? <PlanModal setCompanyStatus={controller.alterCompanyStatus}/> : null}
                {controller.companyStatus == 'NO_CREDIT_CARD' ? <EditCompanyPayment setCompanyStatus={controller.alterCompanyStatus} noModal={true}/> : null}
            </S.errorInfoWindow>
        </S.main>
    )
}

export default PaymentMissing;
import React, { useRef } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../utils/general';
import moment from 'moment-timezone';
const Calendar = (props: {
    setOpen?: any,
    calendarType?: string,
    position?: string,
    changeMonth?: any,
    width?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    },
    height?: {
        pc?: string,
        tablet?: string,
        mobile?: string
    },
    startValue?: any,
    onCellClick?: any,
    onTimeChange?: any,
    onDateTimeChange?: any,
    closeOnDateSelect?: any,
    eventDays?: any[],
    headerClick?: any,
}) => {
    const controller = useController(props);

    return (
        <S.calendar position={props.position} width={props.width} height={props.height}>
            <S.date calendarType={props.calendarType ?? 'datetime'}>
                <S.header>
                    <S.month>
                        <S.prevMonth onClick={() => controller.prevMonth()} icon={general.icons.faArrowLeft} />
                        <S.monthName key={controller.date?.getMonth()} onClick={(e) => {
                            props.headerClick && props.headerClick(e);
                        }}>{controller.months[controller.date?.getMonth() ?? 0].name} {controller.date?.getFullYear()}</S.monthName>
                        <S.nextMonth onClick={() => controller.nextMonth()} icon={general.icons.faArrowRight} />
                    </S.month>
                    <S.days>
                        {controller.days.map((day, index: number) => (
                            <S.day index={index} key={day.name}>{day.abbrev}</S.day>
                        ))}
                    </S.days>
                </S.header>
                <S.body>
                    {controller.cells.map((cell: any, index: number) => {
                        return (
                            <S.cell key={index} index={index} maxLength={controller.cells.length} onClick={() => controller.handleCellClick(cell)}>
                                <S.cellContent currentDate={moment(cell.date).format('YYYY-MM-DD') == `${controller.chosenDate.year.toString().padStart(2, '0')}-${(controller.chosenDate.month).toString().padStart(2, '0')}-${controller.chosenDate.day.toString().padStart(2, '0')}`} index={index} hasDay={cell.day != ''}>
                                    {props.eventDays && props.eventDays.includes(moment(cell.date).format('DD/MM/YYYY')) && <S.cellHasEvents />}
                                    {cell.day}
                                </S.cellContent>
                            </S.cell>
                        )
                    })}
                </S.body>
            </S.date>
            <S.time calendarType={props.calendarType ?? 'datetime'} width={props.width} height={props.height}>
                <S.timeBlocks>
                    <S.timeBlock>
                        <S.iconArrow onClick={() => controller.changeTime('hour', 'up')} icon={general.icons.faChevronUp} />
                        <S.timeText key={controller.chosenTime.hour}>{controller.chosenTime.hour.toString().padStart(2, '0')}</S.timeText>
                        <S.iconArrow onClick={() => controller.changeTime('hour', 'down')} icon={general.icons.faChevronDown} />
                    </S.timeBlock>
                    <S.timeBlock>
                        <S.iconArrow onClick={() => controller.changeTime('minute', 'up')} icon={general.icons.faChevronUp} />
                        <S.timeText key={controller.chosenTime.minute}>{controller.chosenTime.minute.toString().padStart(2, '0')}</S.timeText>
                        <S.iconArrow onClick={() => controller.changeTime('minute', 'down')} icon={general.icons.faChevronDown} />
                    </S.timeBlock>
                </S.timeBlocks>
                <S.finishButton
                    icon={general.icons.faCheck}
                    onClick={() => controller.finish()}
                />
            </S.time>
        </S.calendar>
    );
}

export default Calendar;
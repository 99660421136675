import React, { useState, useEffect } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import DefaultLoadingFit from '../DefaultLoadingFit';

const SupportModal = (props: {setModalOpen: Function}) => {
    const controller = useController();
    
    return (
        <S.supportModal>
            <S.supportModalContent>
                <S.modalHeader>
                    <S.modalTitle>{'Suporte'.toLocaleUpperCase()}</S.modalTitle>
                    <S.modalClose onClick={() => props.setModalOpen(false)}>
                        <S.closeIcon icon={controller.general.icons.faTimes} />
                    </S.modalClose>
                </S.modalHeader>
                <S.modalBody>
                    <S.supportText>{'Caso tenha alguma dúvida ou problema, entre em contato:'.toLocaleUpperCase()}</S.supportText>
                    <S.supportContact href='mailto:suporte@arezdot.com' target='_blank'>
                        <S.supportIcon icon={controller.general.icons.faEnvelope} />
                        <S.supportContactText>{'suporte@arezdot.com'}</S.supportContactText>
                    </S.supportContact>
                    <S.supportContact href='https://wa.me/5535998811741?text=Olá, preciso de suporte com o Arezdot ERP!' target='_blank'>
                        {/* @ts-ignore */}
                        <S.supportIcon size={'1.5rem'} icon={controller.general.brandIcons.faWhatsapp} />
                        <S.supportContactText>{'+55 35 99881-1741'}</S.supportContactText>
                    </S.supportContact>
                </S.modalBody>
            </S.supportModalContent>
        </S.supportModal>
    )
}

export default SupportModal;
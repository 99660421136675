import styled, {keyframes, css} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';

const flipCard = keyframes`
    from {
        transform: rotateY(180deg);
        opacity: 0;
    }
    to {
        transform: rotateY(0deg);
        opacity: 1;
    }
`;
const flipCardReverse = keyframes`
    from {
        transform: rotateY(-180deg);
    }
    to {
        transform: rotateY(0deg);
    }
`;
export const main = styled.div<{index: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 360px;
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 325px;
    }

    @media (max-width: 900px) {
        width: 280px;
        height: 360px;
    }

    @media (max-width: 700px) {
        height: 360px;
        flex-direction: row;
        align-items: center;
        margin: 10px 0 10px 0;
    }
`;
export const card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    gap: 30px;
    animation: ${flipCardReverse} 0.5s forwards;

    :hover {
        transform: scale(1.05);
    }
`;
const rollingAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
export const icon = styled(general.FontAwesomeIcon)<{playAnimation: boolean}>`
    width: 60px;
    height: 60px;
    color: ${colors.black};
    border-radius: 50%;
    padding: 20px;
    animation: ${rollingAnimation} ${props => props.playAnimation ? '2' : '0'}s infinite linear;

    @media (max-width: 1366px) {
        font-size: 48px;
    }

    @media (max-width: 768px) {
        font-size: 30px;
    }
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
`;
export const title = styled.p`
    font: 600 14px ${fonts.main};
    text-transform: uppercase;
    text-align: center;
    padding: 0 15px;
    color: ${colors.black};
`;
export const cardBack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    background-color: ${colors.black};
    color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    gap: 10px;
    cursor: pointer;
    padding: 20px;
    animation: ${flipCard} 0.5s forwards;
`;
export const cardDataBack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    gap: 10px;
`;
export const dataBit = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 5px;
    gap: 20px;
    border-bottom: 1px solid ${colors.white};

    :last-child {
        border-bottom: none;
    }
`;
export const infoTitle = styled.p`
    font: 600 14px ${fonts.main};
    text-transform: uppercase;
    color: ${colors.white};
`;
export const infoData = styled.p`
    font: 400 14px ${fonts.main};
    color: ${colors.white};
    text-align: right;
`;
export const cardActions = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    gap: 30px;
`;
export const actionIcon = styled(general.FontAwesomeIcon)`
    font-size: 24px;
    color: ${colors.white};
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${colors.white};
    padding: 10px;
    transition: 0.05s;

    :hover {
        background-color: ${colors.white};
        color: ${colors.black};
    }
`;
export const deleteCard = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.red};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    gap: 30px;
    animation: ${flipCardReverse} 0.5s forwards;

    :hover {
        transform: scale(1.05);
    }
`;
export const deleteTitle = styled.p`
    font: 600 14px ${fonts.main};
    text-transform: uppercase;
    text-align: center;
    padding: 0 15px;
    color: ${colors.white};
`;
export const actionDeleteIcon = styled(general.FontAwesomeIcon)`
    font-size: 24px;
    color: ${colors.white};
    border: 1px solid ${colors.white};
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
    transition: 0.05s;
    
    :hover {
        background-color: ${colors.white};
        color: ${colors.black};
    }
`;
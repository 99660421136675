import general from '../../../utils/general';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

export const useController = (props: any) => {
    const [date, setDate] = useState(props.startValue ? moment(props.startValue).tz('America/Sao_Paulo').toDate() : moment().tz('America/Sao_Paulo').toDate());
    const [currentDate, setCurrentDate] = useState(moment().tz('America/Sao_Paulo').toDate());
    const [chosenDate, setChosenDate] = useState({
        day: props.startValue ? moment(props.startValue).tz('America/Sao_Paulo').toDate().getDate() : date.getDate(),
        month: props.startValue ? moment(props.startValue).tz('America/Sao_Paulo').toDate().getMonth() + 1 : date.getMonth() + 1,
        year: props.startValue ? moment(props.startValue).tz('America/Sao_Paulo').toDate().getFullYear() : date.getFullYear(),
    });
    const [chosenTime, setChosenTime] = useState({
        hour: props.startValue ? moment(props.startValue).tz('America/Sao_Paulo').toDate().getHours() : (props.calendarType != date) ? 12 : 0,
        minute: props.startValue ? Math.round(moment(props.startValue).tz('America/Sao_Paulo').toDate().getMinutes() / 5) * 5 : (props.calendarType != date) ? 0 : 0,
    });
    const [days, setDays] = useState([

        {
            name: 'Domingo',
            abbrev: 'Dom',
        },
        {
            name: 'Segunda',
            abbrev: 'Seg',
        },
        {
            name: 'Terça',
            abbrev: 'Ter',
        },
        {
            name: 'Quarta',
            abbrev: 'Qua',
        },
        {
            name: 'Quinta',
            abbrev: 'Qui',
        },
        {
            name: 'Sexta',
            abbrev: 'Sex'
        },
        {
            name: 'Sábado',
            abbrev: 'Sáb',
        }
    ]);
    const [months, setMonths] = useState([
        {
            name: 'Janeiro',
            abbrev: 'Jan',
        },
        {
            name: 'Fevereiro',
            abbrev: 'Fev',
        },
        {
            name: 'Março',
            abbrev: 'Mar',
        },
        {
            name: 'Abril',
            abbrev: 'Abr',
        },
        {
            name: 'Maio',
            abbrev: 'Mai',
        },
        {
            name: 'Junho',
            abbrev: 'Jun',
        },
        {
            name: 'Julho',
            abbrev: 'Jul',
        },
        {
            name: 'Agosto',
            abbrev: 'Ago',
        },
        {
            name: 'Setembro',
            abbrev: 'Set',
        },
        {
            name: 'Outubro',
            abbrev: 'Out',
        },
        {
            name: 'Novembro',
            abbrev: 'Nov',
        },
        {
            name: 'Dezembro',
            abbrev: 'Dez',
        }
    ]);
    const [cells, setCells] = useState<any>([]);

    const prevMonth = () => {
        setDate(() => new Date(date.getFullYear(), date.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setDate(() => new Date(date.getFullYear(), date.getMonth() + 1, 1));
    };

    const handleCellClick = (cell: any) => {
        setChosenDate(() => ({
            day: cell.day,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            date: cell.date,
        }));
        props.onCellClick && props.onCellClick({
            day: cell.day,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            date: cell.date,
        });
        props.onDateTimeChange && props.onDateTimeChange({
            day: cell.day,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            hour: chosenTime.hour,
            minute: chosenTime.minute,
            date: cell.date,
        });
        (props.closeOnDateSelect && props.setOpen) && props.setOpen(false);
    };

    const changeTime = (timeType: 'hour' | 'minute', direction: 'up' | 'down') => {
        if (timeType === 'hour') {
            if (direction === 'up') {
                if (chosenTime.hour < 23) {
                    setChosenTime({
                        ...chosenTime,
                        hour: chosenTime.hour + 1,
                    });
                }
                else {
                    setChosenTime({
                        ...chosenTime,
                        hour: 0,
                    });
                }
            } else {
                if (chosenTime.hour > 0) {
                    setChosenTime({
                        ...chosenTime,
                        hour: chosenTime.hour - 1,
                    });
                }
                else {
                    setChosenTime({
                        ...chosenTime,
                        hour: 23,
                    });
                }
            }
        } else {
            if (direction === 'up') {
                if (chosenTime.minute < 55) {
                    setChosenTime({
                        ...chosenTime,
                        minute: chosenTime.minute + 5,
                    });
                }
                else {
                    setChosenTime({
                        ...chosenTime,
                        minute: 0,
                    });
                }
            } else {
                if (chosenTime.minute > 0) {
                    setChosenTime({
                        ...chosenTime,
                        minute: chosenTime.minute - 5,
                    });
                }
                else {
                    setChosenTime({
                        ...chosenTime,
                        minute: 55,
                    })
                }
            }
        }
    }

    useEffect(() => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const firstDayWeekDay = firstDay.getDay();
        const lastDayWeekDay = lastDay.getDay();
        const totalDays = lastDay.getDate();
        const totalCells = totalDays + firstDayWeekDay + (6 - lastDayWeekDay);
        const tempCells: any = [];
        for (let i = 0; i < totalCells; i++) {
            if (i < firstDayWeekDay || i >= totalDays + firstDayWeekDay) {
                tempCells.push({
                    day: '',
                    date: null,
                });
            } else {
                tempCells.push({
                    day: i - firstDayWeekDay + 1,
                    date: new Date(date.getFullYear(), date.getMonth(), i - firstDayWeekDay + 1),
                });
            }
        }
        setCells((cells:any) => tempCells);
        props.changeMonth && props.changeMonth(date);
    }, [date]);

    const finish = () => {
        props.setOpen && props.setOpen(false);
        props.onCellClick && props.onCellClick({
            ...chosenDate,
            ...chosenTime,
        });
        props.onTimeChange && props.onTimeChange({
            ...chosenTime,
        });
        props.onDateTimeChange && props.onDateTimeChange({
            ...chosenDate,
            ...chosenTime,
        });
    }

    return {
        general,
        date,
        days,
        months,
        cells,
        currentDate,
        chosenDate,
        chosenTime,
        setDate,
        setChosenDate,
        setChosenTime,
        finish,
        changeTime,
        prevMonth,
        nextMonth,
        handleCellClick,
    };
}

export default useController;
import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: fadeIn 0.5s;
`;
export const label = styled.div`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
    margin-bottom: 10px;
`;
export const inputSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
    background: ${colors.black};
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
`;
export const inputSelect = styled.div`
    font-family: ${fonts.main};
    font-size: 16px;
    margin: 0 15px 0 10px;
`;
export const icon = styled(FontAwesomeIcon)`
    font-size: 16px;
    margin-right: 10px;
`;
export const optionsWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    animation: fadeIn 0.5s;
`;
export const optionsModal = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50%; 
    max-height: 400px;
    background-color: ${colors.black};
    color: ${colors.white};
    box-shadow: 0 0 10px ${colors.white};
    border-radius: 5px;
    padding: 5px 15px;
    overflow-y: auto;
    
    @media (max-width: 900px) {
        max-width: 90%;
    }
`;
export const option = styled.div`
    font-family: ${fonts.main};
    font-size: 16px;
    padding: 15px 10px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;
    border-bottom: 1px solid ${colors.white};

    &:last-child {
        border-bottom: none;
    }

    :hover {
        background-color: ${colors.darkestGrey};
    }
`;
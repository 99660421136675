import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';

const MultilineSelect = (props: {
    label: string,
    defaultValue: string | number,
    options: {
        label: string,
        value: string | number,
        function?: (value: any) => any
    }[],
}) => {
    const controller = useController(props);

    return (
        <S.main>
            {controller.optionsShowing && (
                <S.optionsWrapper onMouseDown={(e) => {
                    controller.setOptionsShowing(false)
                }}>
                    <S.optionsModal onMouseDown={(e) => {
                        e.stopPropagation()
                    }}>
                        {props.options.map((option, index) => {
                            return (
                                <S.option key={index} onClick={() => {
                                    controller.handleSetValue(option.label)
                                    option.function && option.function(option)
                                }}>
                                    {option.label}
                                </S.option>
                            );
                        })}
                    </S.optionsModal>
                </S.optionsWrapper>
            )}
            <S.label>{props.label}</S.label>
            <S.inputSelectWrapper onClick={() => controller.setOptionsShowing(!controller.optionsShowing)}>
                <S.inputSelect>{controller.value}</S.inputSelect>
                <S.icon icon={general.icons.faChevronDown} />
            </S.inputSelectWrapper>
        </S.main>
    );
}

export default MultilineSelect;
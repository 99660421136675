import React, { useEffect, useState, useRef } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../../utils/types';
import MenuComponent from '../../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../../components/app/HeaderBarComponent';
import { useGlitch } from 'react-powerglitch'
import moment from 'moment-timezone';

const makeContentEditable = (e: any) => {
    let itemAlreadyFocused = document.activeElement == e.target;
    e.target.contentEditable = true

    if (!itemAlreadyFocused) {
        e.target.focus()
        let range = document.createRange();
        let sel = window.getSelection();
        range.setStart(e.target.childNodes[0], e.target.innerText?.length);
        range.collapse(true);
        sel?.removeAllRanges();
        sel?.addRange(range);
    }
}

const AddColumn = (props: any) => {
    const controller = props.controller

    return (
        <S.addColumnWrapper onMouseDown={(e: any) => {
            controller.setAddColumnModalActive(false)
        }} isModal={props.isModal ?? false}>
            <S.addColumnArea onMouseDown={(e: any) => {
                e.stopPropagation();
            }} isModal={props.isModal ?? false} onSubmit={(e: any) => {
                e.preventDefault();
                if (e.target[0].value == '') {
                    controller.general.notify('O nome da coluna não pode ser vazio', 'error')
                    return;
                }
                controller.createColumn(e.target[0].value);
                e.target[0].value = '';
                controller.setAddColumnModalActive(false)
            }}>
                <S.addColumnInput placeholder='Nome da coluna' />
                <S.addColumnButton>
                    <S.addColumnButtonIcon icon={controller.general.icons.faPlus} />
                    <S.addColumnButtonText>
                        Adicionar coluna
                    </S.addColumnButtonText>
                </S.addColumnButton>
                {props.isModal && (
                    <S.addColumnButton onClick={(e: any) => {
                        e.preventDefault();
                        controller.setAddColumnModalActive(false)
                    }}>
                        <S.addColumnButtonIcon icon={controller.general.icons.faArrowLeft} />
                        <S.addColumnButtonText>
                            Voltar
                        </S.addColumnButtonText>
                    </S.addColumnButton>
                )}
            </S.addColumnArea>
        </S.addColumnWrapper>
    )
}

const Card = (props: {
    controller: any,
    card: any,
    column: any,
    index: number,
    columnTiming: number
}) => {
    const controller = props.controller;
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [cardFocused, setCardFocused] = useState(false);
    const [cardOptionsOpen, setCardOptionsOpen] = useState(false);
    const [checklistOpen, setChecklistOpen] = useState(false);
    const [applyTimingToChecklist, setApplyTimingToChecklist] = useState(true);

    useEffect(() => {
        if (controller.randomToCloseEverything) {
            setCardOptionsOpen(false)
            setChecklistOpen(false)
        }
    }, [controller.randomToCloseEverything])

    useEffect(() => {
        if (!checklistOpen) {
            setApplyTimingToChecklist(true)
        }
    }, [checklistOpen]);

    return (
        <>
            <S.columnCard
                onMouseDown={(e: any) => {
                    e.stopPropagation();
                }}
                cardFocused={cardFocused}
                className={`columnCard dontScroll ${cardOptionsOpen ? 'cardOptionsOpen' : ''}`}
                data-columnid={props.column._id}
                draggable={true}
                data-cardid={props.card._id}
                onDragOver={(e: any) => {
                    e.preventDefault();
                    const rect = e.target.getBoundingClientRect();
                    const offset = e.clientY - rect.top;
                    const height = rect.height;
                    if (offset > height / 2) {
                        props.controller.setDragOverIndex(props.index + 1);
                    } else {
                        props.controller.setDragOverIndex(props.index);
                    }

                    props.controller.setHoveredColumnId(props.column._id);
                }}
                onDragStart={(e: any) => {
                    props.controller.setDraggedCardIndex(props.index);
                    props.controller.setDraggedCardId(props.card._id);
                    props.controller.setDraggedFromColumnId(props.column._id);
                    e.target.classList.add('draggingCard')
                    props.controller.setCardCreateRequest(true)
                    props.controller.setColumnNoDelay(true)
                    setDeleteConfirmation(false)
                    props.controller.setDraggedColumnIndex(null);
                }}
                onDragEnd={(e: any) => {
                    e.preventDefault();
                    e.target.classList.remove('draggingCard')
                    props.controller.setDragOverIndex(null);
                    props.controller.setHoveredColumnId(null);
                    props.controller.setDraggedCardId(null);
                    props.controller.setDraggedCardIndex(null);
                    props.controller.setDraggedFromColumnId(null);
                    props.controller.setDraggedColumnIndex(null);
                }}
                key={props.index}
                timing={(!props.controller.cardCreateRequest ? ((controller.cardCreateRequest ? 0 : props.index) * 70) : 0) + (!controller.columnNoDelay ? (props.columnTiming * 550) : 0)}
            >
                <S.columnCardHasChecklistItems show={props.card?.checklist?.filter((task: any) => !task.finished_at)?.length > 0 && !cardOptionsOpen && !deleteConfirmation}>
                    {props.card?.checklist?.length - (props.card?.checklist?.filter((task: any) => !task.finished_at)?.length)}/{props.card?.checklist?.length}
                </S.columnCardHasChecklistItems>
                {cardOptionsOpen ? (
                    <>
                        <S.cardOptions className='dontScroll'>
                            <S.cardOptionsHeader className='dontScroll'>
                                <S.cardOptionsIcon title="Voltar" icon={controller.general.icons.faArrowLeft} onClick={() => {
                                    setCardOptionsOpen(false)
                                    setChecklistOpen(false)
                                }} />
                                <S.cardOptionsIcon title="Duplicar" icon={controller.general.icons.faFileCirclePlus} onClick={() => {
                                    controller.duplicateCard(props.column._id, props.card._id)
                                    setCardOptionsOpen(false)
                                    setChecklistOpen(false)
                                }} />
                                <S.cardOptionsIcon title="Excluir" icon={controller.general.icons.faTrash} onClick={() => {
                                    setDeleteConfirmation(true)
                                    setCardOptionsOpen(false)
                                    setChecklistOpen(false)
                                }} />
                                <S.cardOptionsIcon title="Copiar" icon={controller.general.icons.faCopy} onClick={() => {
                                    controller.general.copyToClipboard(`${props.card.name}\n${props.card.description}`)
                                    controller.general.notify('Cartão copiado para a área de transferência', 'success')
                                    setCardOptionsOpen(false)
                                    setChecklistOpen(false)
                                }} />
                            </S.cardOptionsHeader>
                            <S.cardOptionsLabel>Titulo</S.cardOptionsLabel>
                            {/* @ts-ignore */}
                            <S.cardOptionsDivInput
                                onClick={(e: any) => {
                                    makeContentEditable(e)
                                    setCardFocused(true)
                                }}
                                suppressContentEditableWarning={true} onBlur={(e: any) => {
                                    let text = e.target.innerText
                                    let newText = text.trim()
                                    if (newText === '') {
                                        e.target.innerText = props.card.name
                                        controller.general.notify('O nome do cartão não pode ser vazio', 'error')
                                    }
                                    else {
                                        if (newText !== text) {
                                            e.target.innerText = newText
                                        }
                                        controller.updateCard(props.column._id, props.card._id, {
                                            name: newText.replace(/\n/g, '').trim()
                                        })
                                        e.target.innerText = newText.replace(/\n/g, '').trim()
                                    }
                                    e.target.contentEditable = false
                                    setCardFocused(false)
                                }} onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.target.blur();
                                    }
                                }}>
                                {props.card.name ?? 'Nome não encontrado'}
                            </S.cardOptionsDivInput>
                            <S.cardOptionsLabel>Descrição</S.cardOptionsLabel>
                            {/* @ts-ignore */}
                            <S.cardOptionsDivInput
                                onClick={(e: any) => {
                                    makeContentEditable(e)
                                    setCardFocused(true)
                                }}
                                suppressContentEditableWarning={true} onBlur={(e: any) => {
                                    let text = e.target.innerText
                                    let newText = text.trim()
                                    if (newText === '') {
                                        e.target.innerText = 'Descrição não informada'
                                        newText = 'Descrição não informada'
                                    }
                                    if (newText !== text) {
                                        e.target.innerText = newText
                                    }
                                    controller.updateCard(props.column._id, props.card._id, {
                                        description: newText.trim()
                                    })
                                    e.target.innerText = newText.trim()
                                    e.target.contentEditable = false
                                    setCardFocused(false)
                                }} onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        /* check if is holding shift */
                                        if (!e.shiftKey) {
                                            e.preventDefault();
                                            e.target.blur();
                                        }
                                    }
                                }
                                }>
                                {props.card.description ?? 'Descrição não informada'}
                            </S.cardOptionsDivInput>
                            <S.cardOptionsChecklistLabelArea checklistOpen={checklistOpen} className='dontScroll' onClick={() => {
                                setChecklistOpen(checklistOpen => !checklistOpen)
                            }}>
                                <S.cardOptionsLabel>Checklist ({props.card?.checklist?.length > 0 ? (props.card?.checklist?.length ?? 0) - ((props.card?.checklist?.filter((task: any) => !task.finished_at)?.length) ?? 0) + '/' + (props.card?.checklist?.length ?? 0) : 0})</S.cardOptionsLabel>
                                <S.cardOptionsShowChecklistIcon checklistOpen={checklistOpen} icon={controller.general.icons.faChevronDown} />
                            </S.cardOptionsChecklistLabelArea>
                            {checklistOpen && (
                                <S.cardOptionsChecklist className='dontScroll'>
                                    {props.card?.checklist.map((item: any, index: number) => (
                                        <S.cardOptionsChecklistItem timing={applyTimingToChecklist ? index : 0} className='dontScroll'>
                                            <S.cardOptionsChecklistItemCheckBox className='dontScroll' onClick={(e: any) => {
                                                controller.updateChecklistItem(props.column._id, props.card._id, item._id, {
                                                    finished_at: item.finished_at ? null : moment().toISOString()
                                                })
                                            }}>
                                                {item.finished_at && <S.cardOptionsChecklistCheckedIcon icon={controller.general.icons.faCheck} />}
                                            </S.cardOptionsChecklistItemCheckBox>
                                            {/* @ts-ignore */}
                                            <S.cardOptionsChecklistText
                                                className='dontScroll'
                                                onClick={(e: any) => {
                                                    makeContentEditable(e)
                                                    setCardFocused(true)
                                                }}
                                                suppressContentEditableWarning={true} onBlur={(e: any) => {
                                                    let text = e.target.innerText
                                                    let newText = text.trim()
                                                    if (newText === '') {
                                                        e.target.innerText = props.card.name
                                                        controller.general.notify('O nome do cartão não pode ser vazio', 'error')
                                                    }
                                                    else {
                                                        if (newText !== text) {
                                                            e.target.innerText = newText
                                                        }
                                                        controller.updateChecklistItem(props.column._id, props.card._id, item._id, {
                                                            name: newText.replace(/\n/g, '').trim()
                                                        })
                                                        e.target.innerText = newText.replace(/\n/g, '').trim()
                                                    }
                                                    e.target.contentEditable = false
                                                    setCardFocused(false)
                                                }} onKeyDown={(e: any) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        e.target.blur();
                                                    }
                                                }}>
                                                {item.finished_at ? <del style={{ outline: 'none' }}>{item.name}</del> : item.name}
                                            </S.cardOptionsChecklistText>
                                            <S.cardOptionsChecklistDeleteIcon className='dontScroll' icon={controller.general.icons.faTrash} onClick={() => controller.deleteChecklistItem(props.column._id, props.card._id, item._id)} />
                                        </S.cardOptionsChecklistItem>
                                    ))}
                                    {props.card?.checklist?.length == 0 && (
                                        <S.generateWithAIArea>
                                            {controller.loadingAIAnswer ? (
                                                <S.loadingAI />
                                            ) : (
                                                <>
                                                    <S.generateWithAI onClick={() => {
                                                        controller.makeChecklistWithAI(
                                                            props.card._id,
                                                            props.column._id,
                                                            props.card.name,
                                                            props.card.description,
                                                            props.column.name
                                                        )
                                                    }}>Gerar com IA</S.generateWithAI>
                                                    <S.generateWithAIObs>Obs: Melhor descrição e título gera uma checklist melhor</S.generateWithAIObs>
                                                </>
                                            )}
                                        </S.generateWithAIArea>
                                    )}
                                    <S.cardOptionsChecklistAddArea
                                        timing={applyTimingToChecklist ? (props.card?.checklist?.length ?? 0) : 0}
                                        className='dontScroll'
                                        onSubmit={(e: any) => {
                                            e.preventDefault();
                                            if (e.target[0].value == '') {
                                                controller.general.notify('O nome do item não pode ser vazio', 'error')
                                                return;
                                            }
                                            controller.addChecklistItem(props.column._id, props.card._id, e.target[0].value)
                                            e.target[0].value = ''
                                            setApplyTimingToChecklist(false)
                                        }}>
                                        <S.cardOptionsChecklistAddInput placeholder='Adicionar item' />
                                        <S.cardOptionsChecklistAddButton icon={controller.general.icons.faPlus} onClick={(e: any) => {
                                            e.preventDefault();
                                            if (e.target.previousSibling.value == '') {
                                                controller.general.notify('O nome do item não pode ser vazio', 'error')
                                                return;
                                            }
                                            controller.addChecklistItem(props.column._id, props.card._id, e.target.previousSibling.value)
                                            e.target.previousSibling.value = ''
                                            setApplyTimingToChecklist(false)
                                        }} />
                                    </S.cardOptionsChecklistAddArea>
                                </S.cardOptionsChecklist>
                            )}
                        </S.cardOptions>
                    </>
                ) : (
                    <>
                        {deleteConfirmation && (
                            <S.deleteConfirmation>
                                <S.deleteConfirmationText>
                                    Deseja realmente deletar este cartão?
                                </S.deleteConfirmationText>
                                <S.deleteConfirmationButtons>
                                    <S.deleteConfirmationButton icon={controller.general.icons.faCheck} onClick={() => {
                                        controller.deleteCard(props.column._id, props.card._id)
                                        setDeleteConfirmation(false)
                                    }} />
                                    <S.deleteConfirmationButton icon={controller.general.icons.faTimes} onClick={() => setDeleteConfirmation(false)} />
                                </S.deleteConfirmationButtons>
                            </S.deleteConfirmation>
                        )}
                        {/* @ts-ignore */}
                        <S.columnCardTitle
                            onClick={(e: any) => {
                                makeContentEditable(e)
                                setCardFocused(true)
                            }}
                            suppressContentEditableWarning={true} onBlur={(e: any) => {
                                let text = e.target.innerText
                                let newText = text.trim()
                                if (newText === '') {
                                    e.target.innerText = props.card.name
                                    controller.general.notify('O nome do cartão não pode ser vazio', 'error')
                                }
                                else {
                                    if (newText !== text) {
                                        e.target.innerText = newText
                                    }
                                    controller.updateCard(props.column._id, props.card._id, {
                                        name: newText.replace(/\n/g, '').trim()
                                    })
                                    e.target.innerText = newText.replace(/\n/g, '').trim()
                                }
                                e.target.contentEditable = false
                                setCardFocused(false)
                            }} onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.target.blur();
                                }
                            }}
                            data-columnid={props.column._id}
                        >{props.card.name}</S.columnCardTitle>
                        <S.columnCardOptionIconDummy icon={controller.general.icons.faEllipsisV} />
                        <S.columnCardOptions>
                            <S.columnCardOptionIcon icon={controller.general.icons.faEllipsisH} onClick={() => {
                                setCardOptionsOpen(true)
                                props.controller.setCardCreateRequest(true)
                                props.controller.setColumnNoDelay(true)
                            }} />
                            <S.columnCardOptionIcon icon={controller.general.icons.faTrash} onClick={() => setDeleteConfirmation(true)} />
                        </S.columnCardOptions>
                    </>
                )}
            </S.columnCard>
        </>
    )
}

const Column = (props: {
    column: any,
    columnIndex: number,
    controller: any
}) => {
    const controller = props.controller;
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteAllCardsConfirmation, setDeleteAllCardsConfirmation] = useState(false);
    const [duplicateConfirmation, setDuplicateConfirmation] = useState(false);
    const [moveAllConfirmation, setMoveAllConfirmation] = useState(false);
    const [generateWithAIOptions, setGenerateWithAIOptions] = useState(false);
    const [aiDescription, setAIDescription] = useState('');
    const [aiAmount, setAIAmount] = useState(1);

    useEffect(() => {
        setDeleteConfirmation(false);
        setDuplicateConfirmation(false);
    }, [optionsOpen]);

    function closeAIOptions() {
        setGenerateWithAIOptions(false)
        setAIDescription('')
        setAIAmount(1)
    }

    function closeAllConfirmations() {
        setOptionsOpen(false)
        setDeleteConfirmation(false)
        setDuplicateConfirmation(false)
        setMoveAllConfirmation(false)
        setDeleteAllCardsConfirmation(false)
        closeAIOptions()
    }

    useEffect(() => {
        if (controller.loadingAIAnswer == false) {
            closeAllConfirmations()
        }
    }, [controller.loadingAIAnswer])

    useEffect(() => {
        if (controller.randomToCloseEverything) {
            closeAllConfirmations()
        }
    }, [controller.randomToCloseEverything])

    useEffect(() => {
        if (aiAmount > 25) {
            setAIAmount(25)
        }
        if (aiAmount < 1) {
            setAIAmount(1)
        }
    }, [aiAmount])

    return (
        <S.column blackColumn={optionsOpen} key={props.columnIndex} timing={controller.columnNoDelay ? 0 : props.columnIndex}
            onClick={(e: any) => {
                e.stopPropagation();
            }}
            onDrop={(e: any) => {
                e.preventDefault();
                e.target.classList.remove('draggingColumn')
                if (!controller.draggedCardId) {
                    const fromIndex = props.controller.draggedColumnIndex;
                    const toIndex = props.columnIndex;

                    if (fromIndex === toIndex) {
                        return;
                    };

                    controller.setDraggedColumnIndex(null);
                    props.controller.reorderColumn(fromIndex, toIndex);
                }
            }}
            onDragOver={(e: any) => {
                e.preventDefault();
                e.target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
            }}>
            {optionsOpen ? (
                <S.columnOptions>
                    <S.columnOptionsHeader>
                        <S.columnOptionsTitle>
                            Opções da coluna {'\n[ ' + props.column.name + ' ]'}
                        </S.columnOptionsTitle>
                        <S.columnOptionsClose icon={controller.general.icons.faTimes} onClick={() => {
                            setOptionsOpen(false)
                            closeAllConfirmations()
                        }} />
                    </S.columnOptionsHeader>
                    <S.columnOptionsArea onMouseDown={(e: any) => {
                        e.stopPropagation();
                    }}>
                        {(!deleteAllCardsConfirmation && !deleteConfirmation && !duplicateConfirmation && !moveAllConfirmation && !generateWithAIOptions) && (
                            <>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setDuplicateConfirmation(true)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faCopy} />
                                    <S.columnOptionsButtonText>
                                        Duplicar coluna
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setDeleteConfirmation(true)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faTrash} />
                                    <S.columnOptionsButtonText>
                                        Deletar coluna
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setDeleteAllCardsConfirmation(true)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faRectangleXmark} />
                                    <S.columnOptionsButtonText>
                                        Excluir todos cartões
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setMoveAllConfirmation(true)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faCopy} />
                                    <S.columnOptionsButtonText>
                                        Mover cartões
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setGenerateWithAIOptions(true)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faBrain} />
                                    <S.columnOptionsButtonText>
                                        Cartões com IA
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={(e: any) => {
                                    setOptionsOpen(false)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faArrowLeft} />
                                    <S.columnOptionsButtonText>
                                        Voltar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                            </>
                        )}
                        {deleteConfirmation && (
                            <S.columnOptionsConfirmation>
                                <S.columnOptionsConfirmationTextArea>
                                    <S.columnOptionsConfirmationIcon icon={controller.general.icons.faTrash} />
                                    <S.columnOptionsConfirmationText>
                                        Deseja realmente excluir esta coluna?
                                    </S.columnOptionsConfirmationText>
                                </S.columnOptionsConfirmationTextArea>
                                <S.columnOptionsButton onClick={() => {
                                    controller.deleteColumn(props.column._id)
                                    closeAllConfirmations()
                                    window.scrollTo(0, 0)
                                    controller.setDraggedColumnIndex(null)
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faCheck} />
                                    <S.columnOptionsButtonText>
                                        Confirmar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={() => setDeleteConfirmation(false)}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faTimes} />
                                    <S.columnOptionsButtonText>
                                        Cancelar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                            </S.columnOptionsConfirmation>
                        )}
                        {deleteAllCardsConfirmation && (
                            <S.columnOptionsConfirmation>
                                <S.columnOptionsConfirmationTextArea>
                                    <S.columnOptionsConfirmationIcon icon={controller.general.icons.faTrash} />
                                    <S.columnOptionsConfirmationText>
                                        Deseja realmente excluir todos os cartões dessa coluna?
                                    </S.columnOptionsConfirmationText>
                                </S.columnOptionsConfirmationTextArea>
                                <S.columnOptionsButton onClick={() => {
                                    controller.deleteAllCardsFromColumn(props.column._id)
                                    closeAllConfirmations()
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faCheck} />
                                    <S.columnOptionsButtonText>
                                        Confirmar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={() => setDeleteAllCardsConfirmation(false)}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faTimes} />
                                    <S.columnOptionsButtonText>
                                        Cancelar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                            </S.columnOptionsConfirmation>
                        )}
                        {duplicateConfirmation && (
                            <S.columnOptionsConfirmation>
                                <S.columnOptionsConfirmationTextArea>
                                    <S.columnOptionsConfirmationIcon icon={controller.general.icons.faCopy} />
                                    <S.columnOptionsConfirmationText>
                                        Deseja duplicar esta coluna e seus cartões?
                                    </S.columnOptionsConfirmationText>
                                </S.columnOptionsConfirmationTextArea>
                                <S.columnOptionsButton onClick={() => {
                                    controller.duplicateColumn(props.column._id)
                                    closeAllConfirmations()
                                }}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faCheck} />
                                    <S.columnOptionsButtonText>
                                        Confirmar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                <S.columnOptionsButton onClick={() => setDuplicateConfirmation(false)}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faTimes} />
                                    <S.columnOptionsButtonText>
                                        Cancelar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                            </S.columnOptionsConfirmation>
                        )}
                        {moveAllConfirmation && (
                            <S.columnOptionsConfirmation>
                                <S.columnOptionsConfirmationTextArea>
                                    <S.columnOptionsConfirmationIcon icon={controller.general.icons.faCopy} />
                                    <S.columnOptionsConfirmationText>
                                        Mover todos os cartões para a coluna:
                                    </S.columnOptionsConfirmationText>
                                </S.columnOptionsConfirmationTextArea>
                                <S.columnOptionsButton onClick={() => setMoveAllConfirmation(false)}>
                                    <S.columnOptionsButtonIcon icon={controller.general.icons.faTimes} />
                                    <S.columnOptionsButtonText>
                                        Cancelar
                                    </S.columnOptionsButtonText>
                                </S.columnOptionsButton>
                                {controller.flowColumns.map((column: any, index: number) => (
                                    <S.columnOptionsButton onClick={() => {
                                        controller.moveAllCards(props.column._id, column._id)
                                        closeAllConfirmations()
                                    }}>
                                        <S.columnOptionsButtonIcon icon={controller.general.icons.faArrowRight} />
                                        <S.columnOptionsButtonText>
                                            {column.name}
                                        </S.columnOptionsButtonText>
                                    </S.columnOptionsButton>
                                ))}
                            </S.columnOptionsConfirmation>
                        )}
                        {generateWithAIOptions && (
                            <S.columnOptionsConfirmation>
                                <S.columnOptionsAI>
                                    {controller.loadingAIAnswer ? (
                                        <S.loadingAI />
                                    ) : (
                                        <>
                                            <S.columnOptionsAIGenerate>
                                                <>
                                                    <S.columnOptionsAILabel>
                                                        Sobre o que serão cartões?
                                                    </S.columnOptionsAILabel>
                                                    <S.columnOptionsAIDescription
                                                        value={aiDescription}
                                                        placeholder='Quanto mais detalhado, melhor'
                                                        onChange={(e: any) => {
                                                            setAIDescription(e.target.value)
                                                        }}
                                                    />
                                                </>
                                                <>
                                                    <S.columnOptionsAILabel>
                                                        Quantidade (Max: 25):
                                                    </S.columnOptionsAILabel>
                                                    <S.columnOptionsAIAmount
                                                        value={aiAmount}
                                                        type='number'
                                                        inputMode='numeric'
                                                        onChange={(e: any) => {
                                                            setAIAmount(e.target.value)
                                                        }}
                                                    />
                                                </>
                                            </S.columnOptionsAIGenerate>
                                            <S.columnOptionsButton onClick={() => setGenerateWithAIOptions(false)}>
                                                <S.columnOptionsButtonIcon icon={controller.general.icons.faTimes} />
                                                <S.columnOptionsButtonText>
                                                    Cancelar
                                                </S.columnOptionsButtonText>
                                            </S.columnOptionsButton>
                                            <S.columnOptionsButton onClick={() => {
                                                controller.makeCardsWithAI(props.column._id, props.column.name, aiDescription, aiAmount)
                                            }}>
                                                <S.columnOptionsButtonIcon icon={controller.general.icons.faArrowRight} />
                                                <S.columnOptionsButtonText>
                                                    Criar cartões
                                                </S.columnOptionsButtonText>
                                            </S.columnOptionsButton>
                                        </>
                                    )}
                                </S.columnOptionsAI>
                            </S.columnOptionsConfirmation>
                        )}
                    </S.columnOptionsArea>
                </S.columnOptions>
            ) : (
                <>
                    <S.columnHeader className="dontScroll" draggable
                        onDragStart={(e: any) => {
                            controller.setDraggedColumnIndex(props.columnIndex);
                            e.target.classList.add('draggingColumn')
                        }}>
                        <S.columnHeaderText onBlur={(e: any) => {
                            let text = e.target.innerText
                            let newText = text.replace(/\([0-9]*\)/, '').trim()
                            if (newText === '') {
                                e.target.innerText = props.column.name + ` (${props.column?.cards?.length ?? 0})`
                                controller.general.notify('O nome da coluna não pode ser vazio', 'error')
                            }
                            else {
                                if (newText !== text) {
                                    e.target.innerText = newText
                                }
                                controller.updateColumn(newText.replace(/\n/g, '').trim(), props.column._id)
                                e.target.innerText = newText.replace(/\n/g, '').trim() + ` (${props.column.cards.length})`
                                e.target.contentEditable = false
                            }
                        }} onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                e.target.blur();
                            }
                        }}
                            onClick={(e: any) => {
                                makeContentEditable(e)
                            }}
                            suppressContentEditableWarning={true}>
                            {(props.column.name ?? 'Nome não encontrado') + ` (${props.column?.cards?.length ?? 0})`}
                        </S.columnHeaderText>
                        <S.columnHeaderOptions>
                            {/* <S.columnHeaderOptionIcon icon={controller.general.icons.faRobot} /> */}
                            <S.columnHeaderOptionIcon icon={controller.general.icons.faEllipsisH} onClick={() => {
                                setOptionsOpen(true)
                                controller.setCardCreateRequest(false)
                            }} />
                        </S.columnHeaderOptions>
                    </S.columnHeader>
                    {/* @ts-ignore */}
                    <S.columnCardsArea data-columnid={props.column._id} id={props.column._id} onDrop={(e: any) => {
                        e.preventDefault();
                        const cardId = props.controller.draggedCardId;
                        const fromColumnId = props.controller.draggedFromColumnId;
                        const fromIndex = parseInt(props.controller.draggedCardIndex ?? 0);
                        const toColumnId = props.column._id;
                        const toIndex = props.controller.dragOverIndex ?? 0;

                        if (fromColumnId === toColumnId) {
                            props.controller.reorderCard(cardId, fromColumnId, fromIndex, toIndex);
                        } else {
                            props.controller.moveCard(cardId, fromColumnId, toColumnId, toIndex);
                        }

                        props.controller.setDragOverIndex(null);
                        props.controller.setHoveredColumnId(null);
                        props.controller.setDraggedCardId(null);
                        props.controller.setDraggedCardIndex(null);
                        props.controller.setDraggedFromColumnId(null);
                        props.controller.setDraggedColumnIndex(null);
                    }}
                        onDragOver={(e: any) => {
                            e.preventDefault();
                        }}>
                        {(props.column?.cards ?? [])?.filter((card: any) => card).map((card: any, index: number) => (
                            <>
                                {props.controller.hoveredColumnId === props.column._id &&
                                    props.controller.dragOverIndex === index && (
                                        <S.columnCardPreview />
                                    )}
                                <Card
                                    key={card._id}
                                    controller={props.controller}
                                    card={card}
                                    column={props.column}
                                    index={index}
                                    columnTiming={props.columnIndex}
                                />
                            </>
                        ))}

                        {props.controller.hoveredColumnId === props.column._id &&
                            props.controller.dragOverIndex === props.column?.cards?.length && (
                                <S.columnCardPreview />
                            )}
                    </S.columnCardsArea>
                    <S.columnFooter onSubmit={(e: any) => {
                        e.preventDefault();
                        if (e.target[0].value == '') {
                            controller.general.notify('O nome do cartão não pode ser vazio', 'error')
                            return;
                        }
                        controller.addCard(props.column._id, e.target[0].value);
                        e.target[0].value = '';
                    }}>
                        {/* @ts-ignore */}
                        <S.columnFooterAddCardInput contentEditable="plaintext-only" placeholder='Adicionar cartão' />
                        <S.columnFooterAddCardButton type='submit' value='Adicionar' />
                    </S.columnFooter>
                </>
            )}
        </S.column >
    )
}

const EditFlowModal = (props: {
    controller: any,
    flow: any
}) => {
    const controller = props.controller;
    const [flowName, setFlowName] = useState(props.flow?.name ?? '');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    return (
        <S.editFlowModal onMouseDown={(e: any) => {
            controller.setEditFlowModalOpen(false)
        }}>
            {deleteConfirmation && (
                <S.deleteConfirmation onMouseDown={(e: any) => {
                    e.stopPropagation();
                }}>
                    <S.deleteConfirmationText>
                        Deseja realmente deletar este fluxo juntamente < br /> com todos os seus cards e colunas?
                    </S.deleteConfirmationText>
                    <S.deleteConfirmationButtons>
                        <S.deleteConfirmationButton icon={controller.general.icons.faCheck} onClick={() => {
                            controller.deleteFlow()
                            controller.setEditFlowModalActive(false)
                        }} />
                        <S.deleteConfirmationButton icon={controller.general.icons.faTimes} onClick={() => setDeleteConfirmation(false)} />
                    </S.deleteConfirmationButtons>
                </S.deleteConfirmation>
            )}
            <S.editFlowModalArea onMouseDown={(e: any) => {
                e.stopPropagation();
            }}>
                <S.editFlowModalHeader>
                    <S.editFlowModalTitle>
                        Editar fluxo
                    </S.editFlowModalTitle>
                    <S.editFlowModalClose icon={controller.general.icons.faTimes} onClick={() => {
                        controller.setEditFlowModalOpen(false)
                    }} />
                </S.editFlowModalHeader>
                <S.editFlowModalContent>
                    <S.editFlowModalLabel>
                        Nome do fluxo
                    </S.editFlowModalLabel>
                    <S.editFlowModalInput
                        value={flowName}
                        onChange={(e: any) => {
                            setFlowName(e.target.value)
                        }}
                    />
                    <S.editFlowModalButton onClick={() => {
                        if (flowName == '') {
                            controller.general.notify('O nome do fluxo não pode ser vazio', 'error')
                            return;
                        }
                        controller.updateFlow({ name: flowName })
                        controller.setEditFlowModalOpen(false)
                    }}>
                        Salvar
                    </S.editFlowModalButton>
                    <S.editFlowModalButton onClick={() => {
                        setDeleteConfirmation(true)
                    }}>
                        Excluir Fluxo
                    </S.editFlowModalButton>
                </S.editFlowModalContent>
            </S.editFlowModalArea>
        </S.editFlowModal>
    )
}

const FlowArea = (props: defaultProps) => {
    const controller = useController();
    const glitch = useGlitch();
    const columnsAreaRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const handleMouseDown = (e: any) => {
        if (e.target.parentElement.className.includes('dontScroll') || e.target.className.includes('dontScroll') || controller.draggedColumnIndex != null) return;
        isDragging.current = true;
        startX.current = e.pageX - (columnsAreaRef.current?.offsetLeft || 0);
        scrollLeft.current = columnsAreaRef.current?.scrollLeft || 0;
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current || !columnsAreaRef.current) return;
        e.preventDefault();
        const x = e.pageX - (columnsAreaRef.current.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        columnsAreaRef.current.scrollLeft = scrollLeft.current - walk;
    };

    const handleMouseUp = () => {
        isDragging.current = false; // Desativa o arraste
    };

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName={`Opções do fluxo ${(controller.flow?.title || controller.flow?.name) != '' ? `- [${controller.flow?.title ?? controller.flow?.name}]` : ''}`} />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main onMouseDown={() => {
            controller.setRandomToCloseEverything(controller.general.randomString(10))
            controller.setColumnNoDelay(true)
        }}>
            <controller.general.DefaultLoading active={controller.loading} />
            <MenuComponent menuPreset={1} />
            {controller.editFlowModalOpen && <EditFlowModal controller={controller} flow={controller.flow} />}
            {controller.addColumnModalActive && <AddColumn isModal={true} controller={controller} />}
            <S.generalArea>
                <HeaderBarComponent pageName={controller.flow ? `Opções do fluxo ${(controller.flow?.title || controller.flow?.name) != '' ? `- [${controller.flow?.title ?? controller.flow?.name}]` : ''}` : 'Fluxo não encontrado'} />
                {(controller.flow || controller.initialLoad == false) ? (
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <S.flowHeader>
                                <S.flowheaderArea>
                                    <S.flowHeaderButton onClick={() => {
                                        controller.general.GoToRoute('/app/flows');
                                    }}>
                                        {/* @ts-ignore */}
                                        <S.flowHeaderButtonIcon icon={controller.general.icons.faArrowLeft} />
                                        <S.flowHeaderButtonText>
                                            Voltar
                                        </S.flowHeaderButtonText>
                                    </S.flowHeaderButton>
                                    <S.flowHeaderButton onClick={() => {
                                        controller.setAddColumnModalActive(true)
                                    }}>
                                        {/* @ts-ignore */}
                                        <S.flowHeaderButtonIcon icon={controller.general.icons.faPlus} />
                                        <S.flowHeaderButtonText>
                                            Adicionar coluna
                                        </S.flowHeaderButtonText>
                                    </S.flowHeaderButton>
                                </S.flowheaderArea>
                                <S.flowheaderArea>
                                    <S.flowHeaderButton onClick={() => {
                                        controller.setEditFlowModalOpen(true)
                                    }}>
                                        {/* @ts-ignore */}
                                        <S.flowHeaderButtonIcon icon={controller.general.icons.faGear} />
                                        <S.flowHeaderButtonText>
                                            Configurações
                                        </S.flowHeaderButtonText>
                                    </S.flowHeaderButton>
                                </S.flowheaderArea>
                            </S.flowHeader>
                            <S.columnsArea
                                id='columnsArea'
                                ref={columnsAreaRef}
                                onMouseDown={handleMouseDown}
                                onMouseMoveCapture={handleMouseMove}
                                onMouseUp={handleMouseUp}>
                                {controller.flowColumns.map((column: any, columnIndex: number) => (
                                    <Column column={column} columnIndex={columnIndex} controller={controller} />
                                ))}
                                <S.column key={'addColumn'} timing={!controller.columnNoDelay ? (controller.flowColumns?.length ?? 0) : 0} eraseBorder={controller.flowColumns?.length > 0}>
                                    <AddColumn isModal={false} controller={controller} />
                                </S.column>
                            </S.columnsArea>
                        </S.operationsArea>
                    )
                ) : (
                    <S.flowColumnNotFound>
                        <div ref={glitch.ref}>
                            <S.flowColumnNotFoundIcon icon={controller.general.icons.faRectangleXmark} />
                        </div>
                        <S.flowColumnNotFoundTitle ref={glitch.ref}>
                            Fluxo não encontrado
                        </S.flowColumnNotFoundTitle>
                    </S.flowColumnNotFound>
                )}
            </S.generalArea>
        </S.main>
    );
}

export default FlowArea;
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemUser from '../../../components/app/Cards/ActionCard';
import Card from '../../../components/app/Cards/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Plans = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`plan/create/`} canAddCustomFields={false} title='Conta' type={controller.general.enums.itemTypes.PLAN} item={controller.planCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`plan/update/${controller.currentItem._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.PLAN} item={{...controller.currentItem, can_be_deleted: false}} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} hasTimeline={true} /> : null}
            <MenuComponent menuPreset={2} />
            <S.generalArea>
                <HeaderBarComponent pageName='Planos' />
                <S.operationsArea>
                    <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                        <NewItemUser mainIcon='faDollar' pageItem='Plano'>
                            Adicionar <br /> plano
                        </NewItemUser>
                    </div>
                    {(controller.plans ?? []).map((item: any, index) => {
                        return (
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.setCurrentItem({...item, infos: [
                                    { key: 'name', type: "text", name: 'Nome', value: item.name, disabled: true, required: true },
                                    { key: 'description', type: "text", name: 'Descrição', value: item.description, disabled: true},
                                    { key: 'value', type: "money", name: 'Valor mensal', value: controller.general.formatMoney(item.value.toString()), disabled: true, required: true},
                                    { key: 'planItems', type: "text", name: 'Itens no plano (separados por ";")', value: item.planItems.reduce((planString: string, planItem: string) => `${planString}; ${planItem}`), disabled: true, required: true },
                                    { key: 'showOnWebsite', type: "checkbox", name: 'Mostrar plano', value: item.showOnWebsite, disabled: true },
                                    { key: 'resources_nf', type: "number", name: 'Notas Fiscais', value: item.resources?.nf ?? 0, disabled: true, required: true},
                                    { key: 'resources_monthlyMails', type: "number", name: 'Emails mensais', value: item.resources?.monthlyMails ?? 0, disabled: true, required: true},
                                    { key: 'resources_activeForms', minValue: -1, type: "number", name: 'Formulários ativos', value: item.resources?.activeForms ?? 0, disabled: true, required: true},
                                    { key: 'module_finances', type: "checkbox", name: 'Modulo de finanças', value: item.planModules?.includes('module_finances'), disabled: true },
                                    { key: 'module_categories', type: "checkbox", name: 'Modulo de categorias', value: item.planModules?.includes('module_categories'), disabled: true },
                                    { key: 'module_stock', type: "checkbox", name: 'Modulo de estoque', value: item.planModules?.includes('module_stock'), disabled: true },
                                    { key: 'module_services', type: "checkbox", name: 'Modulo de serviços', value: item.planModules?.includes('module_services'), disabled: true },
                                    { key: 'module_human_resources', type: "checkbox", name: 'Modulo de recursos humanos', value: item.planModules?.includes('module_human_resources'), disabled: true },
                                    { key: 'module_clients', type: "checkbox", name: 'Modulo de clientes', value: item.planModules?.includes('module_clients'), disabled: true },
                                    { key: 'module_suppliers', type: "checkbox", name: 'Modulo de fornecedores', value: item.planModules?.includes('module_suppliers'), disabled: true },
                                    { key: 'module_schedule', type: "checkbox", name: 'Modulo de agenda', value: item.planModules?.includes('module_schedule'), disabled: true },
                                    { key: 'module_marketing', type: "checkbox", name: 'Modulo de marketing', value: item.planModules?.includes('module_marketing'), disabled: true },
                                    { key: 'module_forms', type: "checkbox", name: 'Modulo de formulários', value: item.planModules?.includes('module_forms'), disabled: true },
                                    { key: 'module_stores', type: "checkbox", name: 'Modulo de lojas', value: item.planModules?.includes('module_stores'), disabled: true },
                                    { key: 'module_automation', type: "checkbox", name: 'Modulo de automação', value: item.planModules?.includes('module_automation'), disabled: true },
                                    { key: 'module_integrations', type: "checkbox", name: 'Modulo de integrações', value: item.planModules?.includes('module_integrations'), disabled: true },
                                    { key: 'module_fiscal', type: "checkbox", name: 'Modulo fiscal', value: item.planModules?.includes('module_fiscal'), disabled: true },
                                    { key: 'module_ai', type: "checkbox", name: 'Modulo de inteligência artificial', value: item.planModules?.includes('module_ai'), disabled: true },
                                    { key: 'module_teams', type: "checkbox", name: 'Modulo de times', value: item.planModules?.includes('module_teams'), disabled: true },
                                    { key: 'module_goals', type: "checkbox", name: 'Modulo de Metas', value: item.planModules?.includes('module_goals'), disabled: true },
                                    { key: 'module_vehicles', type: "checkbox", name: 'Modulo de Veículos', value: item.planModules?.includes('module_vehicles'), disabled: true },
                                    { key: 'module_patrimony', type: "checkbox", name: 'Modulo de Patrimônio', value: item.planModules?.includes('module_patrimony'), disabled: true },
                                    { key: 'module_leads', type: "checkbox", name: 'Modulo de Leads', value: item.planModules?.includes('module_leads'), disabled: true },
                                    { key: 'module_flows', type: "checkbox", name: 'Modulo de Fluxos', value: item.planModules?.includes('module_flows'), disabled: true },
                                ], timeline: item?.timeline?.length > 0 ? item.timeline : []})
                                controller.setEditModalActive(active => !active)
                            }} key={`accounts_${index}`}>
                                <Card title={controller.general.formatName(item.name) ?? 'Não informado'} subtitle={item.email} infos={[
                                    { key: 'name', type: "text", name: 'Nome', value: controller.general.formatName(item.name), disabled: true },
                                    { key: 'description', type: "text", name: 'Descrição', value: item.description, disabled: true},
                                    { key: 'planItems', type: "text", name: 'Itens no plano', value: item.planItems.reduce((completeString: string, planItem: string) => `${completeString}<br/><br/>${planItem}`), disabled: true },
                                    { key: 'showOnWebsite', type: "checkbox", name: 'Mostrar plano', value: item.showOnWebsite ? 'Sim' : 'Não', disabled: true },
                                    { key: 'resources_nf', type: "number", name: 'Notas Fiscais', value: item.resources?.nf ?? 'Não informado', disabled: true },
                                    { key: 'resources_monthlyMails', type: "number", name: 'Emails mensais', value: item.resources?.monthlyMails ?? 'Não informado', disabled: true },
                                    { key: 'resources_activeForms', type: "number", name: 'Formulários ativos', value: item.resources?.activeForms ?? 'Não informado', disabled: true },
                                    { key: 'module_finances', type: "checkbox", name: 'Finanças', value: item.planModules?.includes('module_finances') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_categories', type: "checkbox", name: 'Categorias', value: item.planModules?.includes('module_categories') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_stock', type: "checkbox", name: 'Estoque', value: item.planModules?.includes('module_stock') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_services', type: "checkbox", name: 'Serviços', value: item.planModules?.includes('module_services') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_human_resources', type: "checkbox", name: 'Recursos Humanos', value: item.planModules?.includes('module_human_resources') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_clients', type: "checkbox", name: 'Clientes', value: item.planModules?.includes('module_clients') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_suppliers', type: "checkbox", name: 'Fornecedores', value: item.planModules?.includes('module_suppliers') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_schedule', type: "checkbox", name: 'Agenda', value: item.planModules?.includes('module_schedule') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_marketing', type: "checkbox", name: 'Marketing', value: item.planModules?.includes('module_marketing') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_forms', type: "checkbox", name: 'Formulários', value: item.planModules?.includes('module_forms') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_stores', type: "checkbox", name: 'Lojas', value: item.planModules?.includes('module_stores') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_automation', type: "checkbox", name: 'Automação', value: item.planModules?.includes('module_automation') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_integrations', type: "checkbox", name: 'Integrações', value: item.planModules?.includes('module_integrations') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_fiscal', type: "checkbox", name: 'Notas fiscais', value: item.planModules?.includes('module_fiscal') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_ai', type: "checkbox", name: 'Inteligência artificial', value: item.planModules?.includes('module_ai') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_teams', type: "checkbox", name: 'Times', value: item.planModules?.includes('module_teams') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_goals', type: "checkbox", name: 'Metas', value: item.planModules?.includes('module_goals') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_vehicles', type: "checkbox", name: 'Veículos', value: item.planModules?.includes('module_vehicles') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_patrimony', type: "checkbox", name: 'Patrimônios', value: item.planModules?.includes('module_patrimony') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_leads', type: "checkbox", name: 'Leads', value: item.planModules?.includes('module_leads') ? 'Sim' : 'Não', disabled: true },
                                    { key: 'module_flows', type: "checkbox", name: 'Fluxos', value: item.planModules?.includes('module_flows') ? 'Sim' : 'Não', disabled: true },
                                ]} />
                            </div>
                        )
                    })}
                </S.operationsArea>
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Plans;
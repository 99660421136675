export const colors = {
    green: '#7AAB72',
    darkGreen: '#4A7C4A',
    priceGreen: '#108423',
    priceGreenHover: '#0d6e1f',
    lightGrey: '#F2F2F2',
    veryLightGrey: '#F9F9F9',
    grey: '#D3D2D2',
    darkGrey: '#A9A9A9',
    darkerGrey: '#5c5c5c',
    darkeredGrey: '#404040',
    darkestGrey: '#333333',
    lightRed: '#E57373',
    darkRed: '#C0392B',
    red: '#E74C3C',
    pureRed: '#ff0000',
    white: '#FFFFFF',
    backgroundWhite: "#ededed",
    black: '#000000',
    orange: '#ed8f09',
    lightOrange: '#f5a623',
    darkOrange: '#e66e00',
    darkerOrange: '#d35400',
    lightBlue: "#4d94ff",
    linkBlue: '#0000EE',
    primaryYellow: "#D6C000",
}
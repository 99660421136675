import styled, { keyframes, css } from 'styled-components';
import general from '../../../../utils/general';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import { ReactSortable } from 'react-sortablejs';

const popUpAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.1);
    }
    70% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
`;
export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }

    @media (max-width: 500px) {
        width: 1px;
    }
`;
export const operationsArea = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden
`;

export const flowHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 60px);
    padding: 0 10px 10px 10px;
    margin: 20px 20px 0 20px;
    border-bottom: 1px solid ${colors.darkGrey};

    @media (max-width: 500px) {
        padding: 0 10px 10px 10px;
        margin: 0 20px 0 20px;
        justify-content: center;
        gap: 10px;
    }
`;
export const flowheaderArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media (max-width: 500px) {
        gap: 10px;
    }
`;
export const flowHeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
    gap: 10px;
    background: ${colors.black};
    color: ${colors.white};
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 500px) {
        padding: 5px 20px;
    }
`;
export const flowHeaderButtonIcon = styled(general.FontAwesomeIcon)`
    font-size: 18px;
`;
export const flowHeaderButtonText = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 500px) {
        display: none;
    }
`;
/* Flow Modal */
/* <S.editFlowModal>
            <S.editFlowModalArea>
                <S.editFlowModalHeader>
                    <S.editFlowModalTitle>
                        Editar fluxo
                    </S.editFlowModalTitle>
                    <S.editFlowModalClose icon={controller.general.icons.faTimes} onClick={() => {
                        controller.setEditFlowModalActive(false)
                    }} />
                </S.editFlowModalHeader>
                <S.editFlowModalContent>
                    <S.editFlowModalLabel>
                        Nome do fluxo
                    </S.editFlowModalLabel>
                    <S.editFlowModalInput
                        value={flowName}
                        onChange={(e: any) => {
                            setFlowName(e.target.value)
                        }}
                    />
                    <S.editFlowModalButton onClick={() => {
                        controller.updateFlow(flowName)
                    }}>
                        Salvar
                    </S.editFlowModalButton>
                    <S.editFlowModalButton onClick={() => {
                        setDeleteConfirmation(true)
                    }}>
                        Excluir
                    </S.editFlowModalButton>
                    {deleteConfirmation && (
                        <S.deleteConfirmation>
                            <S.deleteConfirmationText>
                                Deseja realmente deletar este fluxo?
                            </S.deleteConfirmationText>
                            <S.deleteConfirmationButtons>
                                <S.deleteConfirmationButton icon={controller.general.icons.faCheck} onClick={() => {
                                    controller.deleteFlow()
                                    controller.setEditFlowModalActive(false)
                                }} />
                                <S.deleteConfirmationButton icon={controller.general.icons.faTimes} onClick={() => setDeleteConfirmation(false)} />
                            </S.deleteConfirmationButtons>
                        </S.deleteConfirmation>
                    )}
                </S.editFlowModalContent>
            </S.editFlowModalArea>
        </S.editFlowModal> */
export const editFlowModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
`;

export const editFlowModalArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    gap: 10px;
    background: ${colors.white};
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px 0 ${colors.darkGrey};
    animation: ${popUpAnimation} 0.3s ease;

    @media(max-width: 500px) {
        width: 200px;
    }
`;

export const editFlowModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.darkGrey};
`;

export const editFlowModalTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 10px;
    outline: none;
`;

export const editFlowModalClose = styled(general.FontAwesomeIcon)`
    font-size: 22px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
`;

export const editFlowModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 10px;
`;

export const editFlowModalLabel = styled.h2`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
`;

export const editFlowModalInput = styled.input`
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    outline: none;
`;

export const editFlowModalButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    cursor: pointer;
    background: ${colors.black};
    color: ${colors.white};

    &:hover {
        transform: scale(1.05);
    }
`;

/* Columns Area */
export const columnsArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: calc(100% - 85px);
    padding: 15px 20px 0 35px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        height: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }

    @media (max-width: 500px) {
        padding: 15px 0 0 8px;
        height: calc(100% - 55px);
    }
`;
const showColumnAnimation = keyframes`
    0% {
        transform: scaleX(0);
    }
    40% {
        transform: scaleX(1.1);
    }
    70% {
        transform: scaleX(0.9);
    }
    100% {
        transform: scaleX(1);
    }
`;
export const column = styled.div<{ timing: number, eraseBorder?: boolean, blackColumn?: boolean }>`
    display: flex;
    flex-direction: column;
    transform-origin: left;
    transform: scaleX(0);
    min-width: 270px;
    height: calc(100% - 45px);
    border-right: 1px solid ${colors.darkGrey};
    padding: 15px 15px 15px 15px;
    animation: ${showColumnAnimation} 0.5s ease ${props => props.timing * 0.5}s forwards;
    background: ${props => props.blackColumn ? colors.black : 'transparent'};
    border-radius: ${props => props.blackColumn ? '5px' : '0'};
    overflow: hidden;

    &:last-child {
        border-right: ${props => props.eraseBorder ? 'none' : '1px solid ' + colors.darkGrey};
    }

    @media (max-width: 500px) {
        min-width: 210px;
    }

    &.draggingColumn {
        transform: scaleX(0);
    }
`;
export const columnOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 240px;
    color: ${colors.white};
    margin: 0 15px;
    height: 100%;
    transition-duration: 0s;

    @media (max-width: 500px) {
        width: 180px;
    }
`;
export const columnOptionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.darkGrey};

    @media (max-width: 500px) {
        width: 100%;
    }
`;
export const columnOptionsTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 10px;
    outline: none;
    word-break: break-word;

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;
export const columnOptionsClose = styled(general.FontAwesomeIcon)`
    font-size: 22px;
    cursor: pointer;

    @media (max-width: 500px) {
        font-size: 20px;
    }

    &:hover {
        transform: scale(1.1);
    }
`;
export const columnOptionsArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 45px);
    gap: 10px;
    flex: 1;

    @media (max-width: 500px) {
        gap: 5px;
    }
`;
export const columnOptionsButton = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: 50px;
    padding: 10px 15px;
    border: 1px solid ${colors.white};
    border-radius: 5px;
    cursor: pointer;
    gap: 15px;
    transition-duration: 0.1s;

    @media (max-width: 500px) {
        padding: 10px 10px;
    }

    &:hover {
        background: ${colors.white};
        color: ${colors.black};
    }
`;
export const columnOptionsButtonIcon = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    transition-duration: 0s;

    @media (max-width: 500px) {
        font-size: 18px;
    }
`;
export const columnOptionsButtonText = styled.h2`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    flex: 1;
    text-align: right;

    @media (max-width: 500px) {
        font-size: 12px;
    }
`;
export const columnOptionsAI = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 10px;
    padding: 10px 5px;
`;
export const columnOptionsAIGenerate = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 15px;
    margin-bottom: 30px;
`;
export const columnOptionsAIDescription = styled.textarea`
    width: calc(100% - 20px);
    max-height: 100px;
    padding: 10px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    outline: none;
    resize: none;
    overflow-x: hidden;
    word-break: break-word;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    color: ${colors.black};
    background: ${colors.white};
`;
export const columnOptionsAIAmount = styled.input`
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    outline: none;
`;
export const columnOptionsAILabel = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    word-break: break-word;
    text-align: left;
    width: 100%;
`;
export const columnOptionsConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 10px;
    animation: ${popUpAnimation} 0.5s ease;
    padding: 15px 5px;

    @media (max-width: 500px) {
        gap: 5px;
    }
`;
export const columnOptionsConfirmationTextArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
`;
export const columnOptionsConfirmationIcon = styled(general.FontAwesomeIcon)`
    font-size: 40px;
    transition-duration: 0s;
    margin-bottom: 10px;
    color: black;
    background: ${colors.white};
    padding: 15px 25px;
    border-radius: 10px;
`;
export const columnOptionsConfirmationText = styled.h2`
    font-family: ${fonts.main};
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    align-content: center;
`;

export const addColumnWrapper = styled.div<{ isModal: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: ${props => props.isModal ? '100vw' : '100%'};
    height: ${props => props.isModal ? '100vh' : '100%'};
    position: ${props => props.isModal ? 'fixed' : 'relative'};
    top: 0;
    left: 0;
    border-radius: ${props => props.isModal ? '0' : '5px'};
    background: ${props => props.isModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
`;
export const addColumnArea = styled.form<{ isModal: boolean }>`
    ${props => props.isModal ? css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 150px;
        position: fixed;
        top: calc(50% - 75px);
        left: calc(50% - 150px);
        gap: 10px;
        background: ${colors.white};
        border-radius: 5px;
        padding: 20px;
        box-shadow: 0 0 10px 0 ${colors.darkGrey};
        animation: ${popUpAnimation} 0.3s ease;

        @media(max-width: 500px) {
            top: calc(50% - 120px);
        }
    ` : css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 10px;    
    `}
`;
export const addColumnInput = styled.input`
    width: calc(100% - 20px);
    height: 40px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    outline: none;
`;
export const addColumnButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    cursor: pointer;
    background: ${colors.black};
    gap: 15px;
`;
export const addColumnButtonIcon = styled(general.FontAwesomeIcon)`
    font-size: 18px;
    color: ${colors.white};
`;
export const addColumnButtonText = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
`;
export const columnHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 270px;
    height: auto;
    padding: 5px 0 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid ${colors.darkGrey};

    @media (max-width: 500px) {
        width: 200px;
    }
`;
export const columnHeaderText = styled.div`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    word-break: break-word;
    margin-right: 10px;
    outline: none;
`;
export const columnHeaderOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;
export const columnHeaderOptionIcon = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    color: ${colors.black};

    &:hover {
        transform: scale(1.1);
    }
`;
export const columnCardsArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    width: 270px;
    height: calc(100% - 45px);
    gap: 15px;
    padding: 10px 0;

    @media (max-width: 500px) {
        width: 200px;
    }
`;
export const columnFooter = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 0;
    border-top: 1px solid ${colors.darkGrey};
`;

export const columnFooterAddCardInput = styled.input`
    width: calc(100% - 20px);
    max-height: 150px;
    padding: 10px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    outline: none;
    resize: none;
    overflow-x: hidden;
    word-break: break-word;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    color: ${colors.black};
    background: ${colors.white};
    margin-bottom: 10px;
`;
export const columnFooterAddCardButton = styled.input`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    cursor: pointer;
    background: ${colors.black};
    color: ${colors.white};

    &:hover {
        transform: scale(1.05);
    }
`;
export const columnCardOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 0 0 0 15px;
    display: none;
    animation: ${popUpAnimation} 0.3s ease;
`;
export const columnCardOptionIconDummy = styled(general.FontAwesomeIcon)`
    font-size: 18px;
    cursor: pointer;
    color: ${colors.darkGrey};
    background: transparent;
    padding: 5px;
    border-radius: 5px;
    display: none;
    
    @media (max-width: 1000px) {
        display: flex;
    }
`;
export const columnCardOptionIcon = styled(general.FontAwesomeIcon)`
    font-size: 14px;
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.darkerGrey};
    padding: 5px;
    border-radius: 5px;

    &:hover {
        transform: scale(1.1);
        background: ${colors.black};
    }
`;
export const columnCardTitle = styled.div`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 400;
    flex: 1;
    min-height: 60px;
    text-align: left;
    align-content: center;
    word-break: break-word;
    outline: none;
    transition-duration: 0s;
`;
const showOptionsAnimation = keyframes`
    0% {
        transform: scale(1, 0.5) rotateY(180deg);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1) rotateY(0deg);
        opacity: 1;
    }
`;
export const columnCard = styled.div<{ timing: number, cardFocused: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: calc(100% - 42px);
    transform: scale(0);
    transform-origin: top;
    height: auto;
    padding: 10px;
    border-radius: 5px;
    cursor: grab;
    animation: ${showColumnAnimation} 0.5s ease ${props => props.timing}ms forwards;
    box-shadow: 0 0 10px 0 ${colors.darkGrey};
    background: ${colors.white};
    transition-duration: 0.3s;

    &.cardOptionsOpen {
        padding: 0;
        width: calc(100% - 20px);
        transform: scaleY(0);
        transition-duration: 0s;
        animation: ${showOptionsAnimation} 0.3s ease forwards;
    }

    ${cardFocused => cardFocused.cardFocused ? `
        background: ${colors.black};
        color: ${colors.white};
    ` : ``}

    &:hover {
        ${columnCardOptions} {
            display: ${cardFocused => cardFocused.cardFocused ? 'none' : 'flex'};
        }
        ${columnCardOptionIconDummy} {
            display: none;
        }
        ${columnCardTitle} {
            text-align: left;
            padding-right: 10px;
            border-right: ${cardFocused => cardFocused.cardFocused ? 'none' : '1px solid ' + colors.darkGrey};
        }
    }

    &.draggingCard {
        opacity: 0;
    }
`;
export const columnCardHasChecklistItems = styled.h2<{show: boolean}>`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
    background: ${colors.black};
    padding: 3px 10px;
    border-radius: 5px;
    display: ${props => props.show ? 'block' : 'none'};
    position: absolute;
    top: -10px;
    left: -10px;

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;
export const columnCardPreview = styled.div`
    height: 50px;
`;
const opacityAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
export const deleteConfirmation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    gap: 10px;
    background: ${colors.red};
    border-radius: 5px;
    animation: ${opacityAnimation} 0.3s ease;
`;
export const deleteConfirmationText = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
    text-align: center;
    word-break: break-word;
    padding: 0 5px;

    @media (max-width: 500px) {
        font-size: 12px;
    }
`;
export const deleteConfirmationButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;
export const deleteConfirmationButton = styled(general.FontAwesomeIcon)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 25px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    background: ${colors.darkerGrey};
    color: ${colors.white};

    &:hover {
        background: ${colors.black};
        transform: scale(1.05);
    }
`;
/* column card options */
export const cardOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    transition-duration: 0s;
    background: ${colors.black};
    color: ${colors.white};

    @media (max-width: 500px) {
        width: calc(100% - 20px);
    }
`;
export const cardOptionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 0;
    gap: 10px;
    border-bottom: 1px solid ${colors.darkGrey};

    @media (max-width: 500px) {
        gap: 5px;
    }
`;
export const cardOptionsIcon = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    background: transparent;
    color: white;
    padding: 6px 8px;
    border: 1px solid ${colors.white};
    border-radius: 5px;
    transition-duration: 0s;

    &:hover {
        background: ${colors.white};
        color: ${colors.black};
    }

    @media (max-width: 500px) {
        font-size: 18px;
    }
`;
export const cardOptionsLabel = styled.div`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
`;
export const cardOptionsDivInput = styled.div`
    width: calc(100% - 10px);
    height: auto;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    word-break: break-word;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
`;
export const cardOptionsInputLinks = styled.a`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    color: ${colors.white};
    cursor: pointer;
    word-break: break-word;
    outline: none;
`;
export const cardOptionsChecklistLabelArea = styled.div<{checklistOpen: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 10px);
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: ${props => props.checklistOpen ? css`0px` : css`20px`};
`;
export const cardOptionsShowChecklistIcon = styled(general.FontAwesomeIcon)<{checklistOpen: boolean}>`
    font-size: 16px;
    cursor: pointer;
    transform: ${props => props.checklistOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
const showChecklistAnimation = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
export const cardOptionsChecklist = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
    animation: ${showChecklistAnimation} 0.3s ease forwards;
`;
export const cardOptionsChecklistItem = styled.div<{timing: number}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    background: ${colors.darkeredGrey};
    padding: 5px;
    border-radius: 5px;
    width: calc(100% - 10px);
    height: auto;
    transition-duration: 0.1s;
    cursor: pointer;
    transform: scale(0);
    animation: ${showColumnAnimation} 0.5s ease ${props => props.timing * 0.07}s forwards;
`;
export const cardOptionsChecklistItemCheckBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    cursor: pointer;
`;
export const cardOptionsChecklistCheckedIcon = styled(general.FontAwesomeIcon)`
    font-size: 14px;
`;
export const cardOptionsChecklistText = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    outline: none;
    flex: 1;
`;
export const cardOptionsChecklistDeleteIcon = styled(general.FontAwesomeIcon)`
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;

    @media (max-width: 500px) {
        font-size: 14px;
    }
`;
export const generateWithAIArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: calc(100% - 20px);
    height: auto;
    padding: 10px;
    border-radius: 5px;
    color: ${colors.white};
    transition-duration: 0.1s;
    animation: ${showChecklistAnimation} 0.3s ease forwards;
`;
const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
export const loadingAI = styled.div`
    background: transparent;
    width: 40px;
    height: 40px;
    border: 4px solid ${colors.white};
    border-radius: 50%;
    border-top: 4px solid transparent;
    animation: ${spinAnimation} 1s linear infinite;
`;
export const generateWithAI = styled.p`
    justify-content: center;
    align-items: center;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    width: 100%;
    color: ${colors.white};
    cursor: pointer;
    transition-duration: 0.1s;
`;
export const generateWithAIObs = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: ${colors.white};
`;
export const cardOptionsChecklistAddArea = styled.form<{timing: number}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    width: 100%;
    transform: scale(0);
    animation: ${showColumnAnimation} 0.5s ease ${props => props.timing * 0.07}s forwards;
`;
export const cardOptionsChecklistAddInput = styled.input`
    width: 100%;
    height: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    word-break: break-word;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    color: white;
    background: transparent;
`;
export const cardOptionsChecklistAddButton = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    background: white;
    color: black;
    border-radius: 0 5px 5px 0;
    margin-right: -1px;
    border-right: 1px solid ${colors.white};
`;

export const flowColumnNotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 75px;
`;
export const flowColumnNotFoundIcon = styled(general.FontAwesomeIcon)`
    align-self: center;
    justify-self: center;
    font-size: 144px;
    color: ${colors.black};

    @media (max-width: 500px) {
        font-size: 120px;
    }
`;
export const flowColumnNotFoundTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 26px;
    font-weight: 600;
    text-transflowColumn: uppercase;
    color: ${colors.black};
    text-align: center;
`;
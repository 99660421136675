import general from '../../../../utils/general';
import { useState, useRef } from 'react';

export const useController = (props: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    return {
        general,
        isExpanded,
        setIsExpanded,
        isFlipped,
        setIsFlipped,
        deleteConfirmation,
        setDeleteConfirmation
    };
}

export default useController;
import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import CountUp from 'react-countup';
import { plan } from './types';

/* props: setCompanyStatus */
const PlanModal = (props: any) => {
    const controller = useController(props);
    const countUpDuration = 1;
    const decimals = 2;
    const decimalSeparator = ',';
    const countUpStart = 0;

    return (
        <S.main>
            <S.planPeriodContainer>
                <S.planPeriod selected={controller.companyInfo.planPeriod == 1 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 1 })}>Mensal</S.planPeriod>
                <S.planPeriod selected={controller.companyInfo.planPeriod == 6 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 6 })}>Semestral (-5%)</S.planPeriod>
                <S.planPeriod selected={controller.companyInfo.planPeriod == 12 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 12 })}>Anual (-10%)</S.planPeriod>
            </S.planPeriodContainer>
            <S.plansArea>
                <S.planBackNextContainer toBeReversed={controller.planShowing == 2 ? true : false}>
                    {controller.planShowing > 2 ? <S.planBackNextMobile onClick={() => controller.changeShowingPlan('back')} icon={controller.general.icons.faChevronLeft}></S.planBackNextMobile> : null}
                    {controller.planShowing < 4 ? <S.planBackNextMobile onClick={() => controller.changeShowingPlan('next')} icon={controller.general.icons.faChevronRight}></S.planBackNextMobile> : null}
                </S.planBackNextContainer>

                {controller.plans.map((plan: plan, index: number) => {
                    return (
                        <S.plan key={index} selected={controller.companyInfo.planSelected == plan._id ? true : false} planShowing={controller.planShowing} onClick={() => {
                            controller.setCompanyInfo({ ...controller.companyInfo, planSelected: plan._id });
                            controller.setPlanSelected(plan._id);
                        }}>
                            <S.planTitle>{plan.name}< br /></S.planTitle>

                            <S.planItems>
                                {plan.planItems.map((item, index) => {
                                    return <S.planItem key={index}>{item}</S.planItem>
                                })}
                            </S.planItems>

                            <S.planPriceAndHire>
                                <S.planPrice>R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={(((plan.value / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly)))} /></S.planPrice>
                                {controller.companyInfo.planPeriod != 0 ? <S.planMonthly>R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={(((((plan.value / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly)))) / controller.companyInfo.planPeriod)} />/mês</S.planMonthly> : null}
                                <S.planHireBtn>{controller.companyInfo.planSelected == plan._id ? 'Selecionado' : 'Selecionar'}</S.planHireBtn>
                            </S.planPriceAndHire>
                        </S.plan>
                    )
                })}
            </S.plansArea>
            <S.inputContainer className="inputContainerInstallments">
                <S.inputLabel>Parcelas (influenciado pelo período do plano):</S.inputLabel>
                <S.inputSelect onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleSelectChange(e)} name='cc.installments' value={controller.companyInfo.cc.installments}>
                    {new Array(controller.companyInfo.planPeriod).fill(0).map((item, index) => {
                        return <S.inputOption key={index} value={index + 1}>{index + 1}x de R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={((((controller.plans.filter(iPlan => iPlan._id == controller.companyInfo.planSelected))[0]?.value * (1 - (controller.cupomDiscount / 100)) / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly))) / (index + 1)} /></S.inputOption>
                    })}
                </S.inputSelect>
                <S.planConfirmBtn onClick={() => {
                    controller.hirePlan()
                }}>Escolher plano</S.planConfirmBtn>
            </S.inputContainer>
        </S.main>
    )
}

export default PlanModal;
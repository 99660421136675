import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const errorInfoWindow = styled.div`
    width: 60%;
    padding: 20px;
    gap: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${colors.backgroundWhite};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

    @media (max-height: 800px) {
        height: 85%;
    }

    @media (max-width: 900px) {
        width: 85%;
    }

    @media (max-width: 500px) {
        width: 87%;
        padding: 20px 10px;
        max-height: 90%;
        flex-direction: column;
        gap: 0;
    }
`;
export const errorInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${colors.darkerGrey};
    padding: 15px 0 15px 20px;

    @media (max-width: 500px) {
        border-left: none;
        padding: 0;
    }
`;

export const errorTitle = styled.h1<{warning: boolean}>`
    font-family: ${fonts.main};
    font-size: 20px;
    font-weight: 600;
    color: ${props => props.warning ? colors.red : colors.green};
    text-align: left;
    margin-right: 20px;

    @media (max-width: 500px) {
        font-size: 16px;
    }
`;
export const errorHeader = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;


    @media(max-height: 800px){
        margin-bottom: 0px;
    }

    @media (max-width: 500px) {
        gap: 10px;
        margin-bottom: 10px;
    }
`;
export const supportBtn = styled(FontAwesomeIcon)`
    color: ${colors.black};
    cursor: pointer;
    transition-duration: 0.3s;
    font-size: 30px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid ${colors.black};
    transition-duration: 0s;

    &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;
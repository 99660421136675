import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [basicDataModal, setBasicDataModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [fiscalDataModal, setFiscalDataModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [cupomActivation, setCupomActivation] = useState(false);
    const [emailIntegrationConfigModal, setEmailIntegrationConfigModal] = useState(false);

    return {
        general,
        basicDataModal,
        setBasicDataModal,
        addressModal,
        setAddressModal,
        fiscalDataModal,
        setFiscalDataModal,
        paymentModal,
        setPaymentModal,
        emailIntegrationConfigModal,
        setEmailIntegrationConfigModal,
        cupomActivation,
        setCupomActivation
    }
}

export default useController;
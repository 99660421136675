import general from '../../../utils/general';
import { useEffect, useState } from 'react';
import { useGlitch } from 'react-powerglitch';

export const useController = () => {
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false)

    return {
        general,
        supportModalOpen,
        setSupportModalOpen,
    }
}

export default useController;
import styled, {keyframes} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';

export const main = styled.div<{index: number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 400px;
    transition-duration: 0.3s;
    transform: scale(0);
    animation popUpAnimation 0.5s ${props => props.index * 0.05}s forwards;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 325px;
    }

    @media (max-width: 900px) {
        width: 280px;
        height: 400px;
    }

    @media (max-width: 700px) {
        height: 360px;
        flex-direction: row;
        align-items: center;
        margin: 10px 0 50px 0;
    }
`;
export const card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 20%;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.black};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
`;
export const icon = styled(general.FontAwesomeIcon)`
    width: 100px;
    height: 100px;
    margin-top: -50px;
    color: ${colors.black};
    background-color: ${colors.backgroundWhite};
    padding: 10px;
    border-radius: 50%;
    border: 2px solid ${colors.black};

    @media (max-width: 1366px) {
        font-size: 48px;
    }

    @media (max-width: 768px) {
        font-size: 30px;
    }
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: calc(100% - 100px);
`;
export const title = styled.p`
    font: 600 25px ${fonts.main};
    text-transform: uppercase;
    text-align: center;
    color: ${colors.white};
    margin-top: -25px;
`;